import React, { useState } from 'react';
import map from 'lodash/map';

import { config } from 'data';
import { formatter } from 'helpers';
import { useLang, useModal, useQueryModal, useTable, useTableQuery } from 'hooks';
import { useUserQuery, useUsersQuery } from 'hooks/queries';
import { Plus } from 'components/icons';
import { Can, TableView } from 'components/layout';
import { Button, Form, Table } from 'components/ui';
import { Uuid } from 'types/common';
import { TableColumns } from 'types/components';
import { User, UserPermission } from 'types/models';
import { UsersParams } from 'types/services';

import styles from './styles.module.css';

import CreateModal from './CreateModal';
import RoleSelect from './RoleSelect';
import ViewModal from './ViewModal';

type TableParams = UsersParams;

const initialTableParams: TableParams = {
  //
};

const UsersView = () => {
  const lang = useLang();
  const createModal = useModal();
  const table = useTable<User, TableParams>([config.USERS_QUERY_KEY], initialTableParams);

  const [userId, setUserId] = useState<Uuid>();

  const viewModal = useQueryModal(config.USERS_QUERY_KEY, setUserId);

  const userQuery = useUserQuery(userId);

  const usersQuery = useUsersQuery({
    page: table.page,
    search: table.params.search || undefined,
    roleId: table.params.roleId || undefined,
  });

  const handleViewClick = (user: User) => () => {
    viewModal.show(user.id);
  };

  const handleDelete = () => {
    setUserId(undefined);
  };

  useTableQuery(table, usersQuery);

  const columns: TableColumns<User> = [
    {
      className: styles.table__email,
      key: 'email',
      title: lang.get('user.list.email'),
      render: (_, user) => <Table.Truncate width="middle">{user.email}</Table.Truncate>,
    }, {
      key: 'name',
      title: lang.get('user.list.name'),
      render: (_, user) => (
        <Table.Truncate>
          {formatter.formatName(user.firstName, user.lastName) || '-'}
        </Table.Truncate>
      ),
    }, {
      key: 'roles',
      title: lang.get('user.list.roles'),
      render: (_, user) => map(user.roles, 'name').join(', ') || '-',
    }, {
      key: 'loginDate',
      title: lang.get('user.list.loginDate'),
      render: (_, user) => user.lastLoginAt ? formatter.formatDateTime(user.lastLoginAt) : '-',
    },
  ];

  return (
    <Can permissions={[UserPermission.USERS_VIEW, UserPermission.USERS_UPDATE]}>
      <TableView
        title={lang.get('user.list.title')}
        actions={(
          <Can permissions={[UserPermission.USERS_UPDATE]}>
            <Button icon={<Plus />} onClick={createModal.show}>
              {lang.get('user.list.create')}
            </Button>
          </Can>
        )}
      >

        <TableView.Filters<TableParams>
          initialValues={initialTableParams}
          values={table.params}
          withSearch
          inline
          onSubmit={table.setParams}
        >
          <Form.Item name="roleId">
            <RoleSelect
              placeholder={lang.get('common.filters.roles')}
              allowClear
              popupMatchSelectWidth={false}
            />
          </Form.Item>
        </TableView.Filters>

        <TableView.Table<User>
          table={table}
          columns={columns}
          rowKey={(user) => user.id}
          loading={usersQuery.isLoading}
          clickable
          onRow={(user) => ({ onClick: handleViewClick(user) })}
        />

        <CreateModal
          open={createModal.open}
          onClose={createModal.hide}
        />

        {userQuery.data && (
          <ViewModal
            user={userQuery.data}
            open={!userQuery.isLoading && viewModal.open}
            onClose={viewModal.hide}
            onDelete={handleDelete}
          />
        )}

      </TableView>
    </Can>
  );
};

export default UsersView;

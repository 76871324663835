import React, { useState } from 'react';

import { config, moment } from 'data';
import { formatter } from 'helpers';
import { useLang, useQueryModal, useTable, useTableQuery } from 'hooks';
import { useAuditLogQuery, useAuditLogsQuery } from 'hooks/queries';
import { DashboardLayout } from 'layouts';
import { TableView, UserInfo } from 'components/layout';
import { UserSelect } from 'components/selects';
import { DateRangePicker, Form, Select } from 'components/ui';
import { Nullable, Uuid } from 'types/common';
import { TableColumns } from 'types/components';
import { AuditLog, AuditLogAction, AuditLogObject } from 'types/models';
import { AuditLogsParams } from 'types/services';

import styles from './styles.module.css';

import Modal from './Modal';

type TableParams = AuditLogsParams & {
  dates?: Nullable<[Nullable<string>, Nullable<string>]>;
};

const initialTableParams: TableParams = {
  //
};

const AuditLogsPage = () => {
  const lang = useLang();
  const table = useTable<AuditLog, TableParams>([config.AUDIT_LOGS_QUERY_KEY], initialTableParams);

  const [logId, setLogId] = useState<Uuid>();

  const modal = useQueryModal(config.AUDIT_LOGS_QUERY_KEY, setLogId);

  const logQuery = useAuditLogQuery(logId);

  const logsQuery = useAuditLogsQuery({
    page: table.page,
    search: table.params.search || undefined,
    userId: table.params.userId || undefined,
    action: table.params.action || undefined,
    objectType: table.params.objectType || undefined,
    dateFrom: (table.params.dates && table.params.dates[0]) || undefined,
    dateTo: (table.params.dates && table.params.dates[1]) || undefined,
  });

  const handleViewClick = (log: AuditLog) => () => {
    modal.show(log.id);
  };

  useTableQuery(table, logsQuery);

  const columns: TableColumns<AuditLog> = [
    {
      className: styles.table__date,
      key: 'date',
      title: lang.get('auditLog.list.date'),
      render: (_, log) => formatter.formatDateTime(log.createdAt),
    }, {
      key: 'user',
      title: lang.get('auditLog.list.user'),
      render: (_, log) => (
        <UserInfo
          name={formatter.formatName(log.creator.firstName, log.creator.lastName)}
          email={log.creator.email}
        />
      ),
    }, {
      className: styles.table__action,
      key: 'action',
      title: lang.get('auditLog.list.action'),
      render: (_, log) => lang.get(`auditLog.actions.${log.action.toLowerCase()}`),
    }, {
      key: 'object',
      title: lang.get('auditLog.list.object'),
      render: (_, log) => lang.get(`auditLog.objects.${log.objectType.toLowerCase()}`),
    },
  ];

  return (
    <DashboardLayout title={lang.get('auditLog.list.title')}>
      <TableView title={lang.get('auditLog.list.title')}>

        <TableView.Filters<TableParams>
          initialValues={initialTableParams}
          values={table.params}
          withSearch
          onSubmit={table.setParams}
        >
          <Form.Item name="userId" label={lang.get('common.filters.user')}>
            <UserSelect placeholder={lang.get('common.actions.all')} allowClear />
          </Form.Item>
          <Form.Item name="action" label={lang.get('auditLog.filters.action')}>
            <Select
              placeholder={lang.get('common.actions.all')}
              options={Object.values(AuditLogAction).map((action) => ({
                value: action,
                label: lang.get(`auditLog.actions.${action.toLowerCase()}`),
              }))}
              allowClear
            />
          </Form.Item>
          <Form.Item name="objectType" label={lang.get('auditLog.filters.object')}>
            <Select
              placeholder={lang.get('common.actions.all')}
              options={Object.values(AuditLogObject).map((object) => ({
                value: object,
                label: lang.get(`auditLog.objects.${object.toLowerCase()}`),
              }))}
              allowClear
            />
          </Form.Item>
          <Form.Item name="dates" label={lang.get('common.filters.date')}>
            <DateRangePicker maxDate={moment()} allowEmpty />
          </Form.Item>
        </TableView.Filters>

        <TableView.Table<AuditLog>
          table={table}
          columns={columns}
          rowKey={(log) => log.id}
          loading={logsQuery.isLoading}
          clickable
          onRow={(log) => ({ onClick: handleViewClick(log) })}
        />

        {logQuery.data && (
          <Modal
            log={logQuery.data}
            open={!logQuery.isLoading && modal.open}
            onClose={modal.hide}
          />
        )}

      </TableView>
    </DashboardLayout>
  );
};

export default AuditLogsPage;

import React from 'react';

import { config, moment } from 'data';
import { formatter } from 'helpers';
import { useLang, useModal, useTable, useTableQuery } from 'hooks';
import { useCurrencyRatesQuery } from 'hooks/queries';
import { DashboardLayout } from 'layouts';
import { Plus } from 'components/icons';
import { Can, TableView } from 'components/layout';
import { CurrencySelect } from 'components/selects';
import { Button, DateRangePicker, Form } from 'components/ui';
import { Nullable } from 'types/common';
import { TableColumns } from 'types/components';
import { CurrencyRate, UserPermission } from 'types/models';
import { CurrencyRatesParams } from 'types/services';

import styles from './styles.module.css';

import Modal from './Modal';

type TableParams = CurrencyRatesParams & {
  dates?: Nullable<[Nullable<string>, Nullable<string>]>;
};

const initialTableParams: TableParams = {
  dates: [
    moment().startOf('day').toISOString(),
    moment().endOf('day').toISOString(),
  ],
};

const CurrencyRatesPage = () => {
  const lang = useLang();
  const modal = useModal();
  const table = useTable<CurrencyRate, TableParams>([config.CURRENCY_RATES_QUERY_KEY], initialTableParams);

  const currencyRatesQuery = useCurrencyRatesQuery({
    page: table.page,
    currency: table.params.currency || undefined,
    dateFrom: (table.params.dates && table.params.dates[0]) || undefined,
    dateTo: (table.params.dates && table.params.dates[1]) || undefined,
  });

  const handleCreateClick = modal.show;

  useTableQuery(table, currencyRatesQuery);

  const columns: TableColumns<CurrencyRate> = [
    {
      className: styles.table__date,
      key: 'date',
      title: lang.get('currencyRate.list.date'),
      render: (_, currencyRate) => formatter.formatDateTime(currencyRate.date),
    }, {
      className: styles.table__name,
      key: 'name',
      title: lang.get('currencyRate.list.currency'),
      render: (_, currencyRate) => currencyRate.targetCurrency,
    }, {
      className: styles.table__rate,
      key: 'rate',
      title: lang.get('currencyRate.list.rate'),
      render: (_, currencyRate) => formatter.formatNumber(currencyRate.rate, config.CURRENCY_RATE_PRECISION),
    },
  ];

  return (
    <DashboardLayout title={lang.get('currencyRate.list.title')}>
      <TableView
        title={lang.get('currencyRate.list.title')}
        actions={(
          <Can permissions={[UserPermission.CURRENCY_RATES_UPDATE]}>
            <Button icon={<Plus />} onClick={handleCreateClick}>
              {lang.get('currencyRate.list.create')}
            </Button>
          </Can>
        )}
      >

        <TableView.Filters<TableParams>
          initialValues={initialTableParams}
          values={table.params}
          inline
          onSubmit={table.setParams}
        >
          <Form.Item name="currency">
            <CurrencySelect
              placeholder={lang.get('common.filters.currencies')}
              allowClear
              popupMatchSelectWidth={false}
            />
          </Form.Item>
          <Form.Item name="dates">
            <DateRangePicker maxDate={moment()} allowClear={false} />
          </Form.Item>
        </TableView.Filters>

        <TableView.Table<CurrencyRate>
          table={table}
          columns={columns}
          rowKey={(currencyRate) => currencyRate.id}
          loading={currencyRatesQuery.isLoading}
        />

        <Modal
          open={modal.open}
          onClose={modal.hide}
        />

      </TableView>
    </DashboardLayout>
  );
};

export default CurrencyRatesPage;

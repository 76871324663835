import { useCallback, useMemo } from 'react';
import { useLang } from 'hooks';

import {
  TransactionDirection,
  TransactionPayoutMethod,
  TransactionStatus,
  TransactionType,
  TransactionTypeCategory,
} from 'types/models';

const useTransactionFormatter = () => {
  const lang = useLang();

  const formatDirection = useCallback((direction: TransactionDirection) => lang.get(`transaction.directions.${direction.toLowerCase()}`), [lang]);

  const formatPayoutMethod = useCallback((method: TransactionPayoutMethod) => lang.get(`transaction.payoutMethods.${method.toLowerCase()}`), [lang]);

  const formatStatus = useCallback((status: TransactionStatus) => lang.get(`transaction.statuses.${status.toLowerCase()}`), [lang]);

  const formatType = useCallback((type: TransactionType) => lang.get(`transaction.types.${type.toLowerCase()}`), [lang]);

  const formatTypeCategory = useCallback((category: TransactionTypeCategory) => lang.get(`transaction.typeCategories.${category.toLowerCase()}`), [lang]);

  return useMemo(() => ({
    formatDirection,
    formatPayoutMethod,
    formatStatus,
    formatType,
    formatTypeCategory,
  }), [
    formatDirection,
    formatPayoutMethod,
    formatStatus,
    formatType,
    formatTypeCategory,
  ]);
};

export default useTransactionFormatter;

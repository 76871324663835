import { useEffect } from 'react';
import { AxiosError } from 'axios';

import {
  DefaultError,
  hashKey,
  QueryKey,
  useQuery as useBaseQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useLang from './lang';
import useTheme from './theme';
import useQueryClient from './query-client';

const KEY = 'query';

type UseQueryExtraOptions = {
  showMessage?: boolean;
  showNotification?: boolean;
};

type UseQuery = <
  TQueryFnData = unknown,
  TError extends DefaultError = AxiosError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & UseQueryExtraOptions) => UseQueryResult<TData, TError>;

const useQuery: UseQuery = ({
  showMessage = true,
  showNotification = true,
  ...options
}) => {
  const lang = useLang();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const query = useBaseQuery({
    retry: false,
    ...options,
  });

  const queryId = hashKey(options.queryKey);

  useEffect(() => {
    if (showMessage && query.isLoading) {
      theme.message?.loading({
        key: KEY,
        content: lang.get('common.query.loading'),
        duration: 0,
      });
    } else {
      theme.message?.destroy(KEY);
    }

    return () => {
      theme.message?.destroy(KEY);
    };
  }, [showMessage, lang, theme.message, query.isLoading]);

  useEffect(() => {
    if (showNotification && query.isError) {
      theme.notification?.error({
        message: lang.get('common.query.error'),
        description: query.error.message,
      });
    }
  }, [showNotification, lang, theme.notification, query.isError, query.error]);

  useEffect(() => {
    return () => {
      const state = queryClient.getQueryState(options.queryKey);

      if (state?.status === 'error') {
        queryClient.resetQueries({ queryKey: options.queryKey, exact: true });
      }
    };
  }, [queryId, queryClient]); /* eslint-disable-line react-hooks/exhaustive-deps */

  return query;
};

export default useQuery;

import { config } from 'data';
import { businessApplicationService } from 'services';
import { useQuery } from 'hooks';

const useAllBusinessApplicationIndustriesQuery = () => useQuery({
  queryKey: [config.BUSINESS_APPLICATION_INDUSTRIES_QUERY_KEY, 'all'],
  queryFn: businessApplicationService.getBusinessApplicationIndustries,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
});

export default useAllBusinessApplicationIndustriesQuery;

import { IParseOptions, IStringifyOptions, parse, stringify } from 'qs';

import { config } from 'data';
import { Uuid } from 'types/common';
import { QueryModalData } from 'types/hooks';

const encodeParams = (params?: object, options?: IStringifyOptions) => stringify(params, {
  ...options,
  arrayFormat: 'brackets',
  allowDots: true,
  encodeValuesOnly: true,
});

const decodeParams = (params: string, options?: IParseOptions) => parse(params, {
  ...options,
  allowDots: true,
});

const encodeQueryParams = (params?: object) => encodeParams(params, { addQueryPrefix: true });

const prepareFilterParams = <Values>(values?: Values) => ({ [config.FILTERS_QUERY_PARAM]: values });

const encodeModalParams = <Params>(data?: QueryModalData<Params>) => encodeQueryParams(prepareModalParams(data));

const prepareModalParams = <Params>(data?: QueryModalData<Params>) => ({ [config.MODAL_QUERY_PARAM]: data });

const toAuditLogs = () => toSettings('/audit-logs');

const toAuth = (hash: Uuid) => `/auth/${hash}`;

const toBusinessAccounts = (path = '') => toDashboard(`/business-accounts${path}`);

const toBusinessAccountsDailyBalance = () => toReports('/business-accounts-daily-balance');

const toBusinessAccountsDailyRevenue = () => toReports('/business-accounts-daily-revenue');

const toBusinessAccount = (id: Uuid, path = '') => toBusinessAccounts(`/${id}${path}`);

const toBusinessAccountDailyBalance = (id: Uuid) => toBusinessAccountReports(id, '/daily-balance');

const toBusinessAccountDailyRemittance = (id: Uuid) => toBusinessAccountReports(id, '/daily-remittance');

const toBusinessAccountDailyTransactions = (id: Uuid) => toBusinessAccountReports(id, '/daily-transactions');

const toBusinessAccountFeeCommissions = (id: Uuid) => toBusinessAccount(id, '/fee-configurations');

const toBusinessAccountMembers = (id: Uuid) => toBusinessAccount(id, '/members');

const toBusinessAccountReports = (id: Uuid, path = '') => toBusinessAccount(id, `/reports${path}`);

const toBusinessAccountVirtualAccounts = (id: Uuid) => toBusinessAccount(id, '/accounts');

const toBusinessApplications = () => toDashboard('/business-applications');

const toClients = () => toDashboard('/clients');

const toCorridors = (path = '') => toSettings(`/corridors${path}`);

const toCorridorTypes = () => toCorridors('/transaction-types');

const toCostManagement = (path = '') => toSettings(`/cost-management${path}`);

const toCurrencyRates = () => toSettings('/currency-rates');

const toDailyRemittance = () => toReports('/daily-remittance');

const toDashboard = (path = '') => `/dashboard${path}`;

const toFeeCommission = (id: Uuid) => toFeeManagement(encodeModalParams({
  id: config.FEE_COMMISSIONS_QUERY_KEY,
  params: id,
}));

const toFeeGroups = () => toFeeManagement('/fee-groups');

const toFeeManagement = (path = '', params?: object) => toSettings(`/fee-management${path}${encodeQueryParams(params)}`);

const toLogin = () => '/';

const toIndividualAccounts = (path = '') => toDashboard(`/individual-accounts${path}`);

const toIndividualAccount = (id: Uuid, path = '') => toIndividualAccounts(`/${id}${path}`);

const toIndividualAccountDailyBalance = (id: Uuid) => toIndividualAccountReports(id, '/daily-balance');

const toIndividualAccountDailyRemittance = (id: Uuid) => toIndividualAccountReports(id, '/daily-remittance');

const toIndividualAccountDailyTransactions = (id: Uuid) => toIndividualAccountReports(id, '/daily-transactions');

const toIndividualAccountFeeCommissions = (id: Uuid) => toIndividualAccount(id, '/fee-configurations');

const toIndividualAccountMembers = (id: Uuid) => toIndividualAccount(id, '/members');

const toIndividualAccountReports = (id: Uuid, path = '') => toIndividualAccount(id, `/reports${path}`);

const toIndividualAccountVirtualAccounts = (id: Uuid) => toIndividualAccount(id, '/accounts');

const toPasswordChange = (hash: Uuid) => `/password-change/${hash}`;

const toPasswordReset = () => '/password-reset';

const toProviders = () => toCostManagement('/providers');

const toProvidersDailyBalance = () => toReports('/providers-daily-balance');

const toProvidersDailySettlement = () => toReports('/providers-daily-settlement');

const toReports = (path = '') => toDashboard(`/reports${path}`);

const toSettings = (path = '') => toDashboard(`/settings${path}`);

const toTransactions = (path = '', params?: object) => toDashboard(`/transactions${path}${encodeQueryParams(params)}`);

const toTransactionBundles = () => toTransactions('/batches');

const toUsers = (path = '') => toSettings(`/users${path}`);

const toUserRoles = () => toUsers('/roles');

const url = {
  encodeParams,
  decodeParams,
  prepareFilterParams,
  prepareModalParams,
  toAuditLogs,
  toAuth,
  toBusinessAccounts,
  toBusinessAccountsDailyBalance,
  toBusinessAccountsDailyRevenue,
  toBusinessAccount,
  toBusinessAccountDailyBalance,
  toBusinessAccountDailyRemittance,
  toBusinessAccountDailyTransactions,
  toBusinessAccountFeeCommissions,
  toBusinessAccountMembers,
  toBusinessAccountReports,
  toBusinessAccountVirtualAccounts,
  toBusinessApplications,
  toClients,
  toCorridors,
  toCorridorTypes,
  toCostManagement,
  toCurrencyRates,
  toDailyRemittance,
  toDashboard,
  toFeeCommission,
  toFeeGroups,
  toFeeManagement,
  toLogin,
  toIndividualAccounts,
  toIndividualAccount,
  toIndividualAccountDailyBalance,
  toIndividualAccountDailyRemittance,
  toIndividualAccountDailyTransactions,
  toIndividualAccountFeeCommissions,
  toIndividualAccountMembers,
  toIndividualAccountReports,
  toIndividualAccountVirtualAccounts,
  toPasswordChange,
  toPasswordReset,
  toProviders,
  toProvidersDailyBalance,
  toProvidersDailySettlement,
  toReports,
  toSettings,
  toTransactions,
  toTransactionBundles,
  toUsers,
  toUserRoles,
};

export default url;

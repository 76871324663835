import { Nullable, Uuid } from './common';

import {
  CountryCode,
  CurrencyCode,
  TransactionDirection,
  TransactionPayoutMethod,
  TransactionStatus,
  TransactionType,
} from './models';

export enum TransactionModalSection {
  BENEFICIARY = 'beneficiary',
  META = 'meta',
  PAYMENT = 'payment',
  RESPONSE = 'response',
  REQUEST = 'request',
  SECURITY = 'security',
}

export type TransactionsViewFilters = {
  providerId?: Uuid;
  country?: CountryCode;
  dates?: Nullable<[Nullable<string>, Nullable<string>]>;
  destinationCurrency?: CurrencyCode;
  direction?: TransactionDirection;
  originCurrency?: CurrencyCode;
  payoutMethod?: TransactionPayoutMethod;
  search?: string;
  status?: TransactionStatus[];
  transactionType?: TransactionType[];
};

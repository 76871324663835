import React, { FC } from 'react';
import { Collapse as BaseCollapse, CollapseProps as BaseCollapseProps } from 'antd';
import { ItemType } from 'rc-collapse/es/interface';
import cx from 'classnames';

import { Override } from 'types/common';

import styles from './styles.module.css';

type CollapseProps = Override<BaseCollapseProps, {
  items: Array<ItemType & {
    hidden?: boolean;
  }>;
}>;

const Collapse: FC<CollapseProps> = ({ className, items, ...props }) => (
  <BaseCollapse
    className={cx(styles.root, className)}
    items={items.filter((item) => item.hidden !== true)}
    {...props}
  />
);

export default Collapse;

import { config } from 'data';
import { businessApplicationService } from 'services';
import { useQuery } from 'hooks';
import { Uuid } from 'types/common';

const useBusinessApplicationKybDetailsQuery = (kybId?: Uuid) => useQuery({
  queryKey: [config.BUSINESS_APPLICATION_KYB_DETAILS_QUERY_KEY, kybId],
  queryFn: () => kybId ? businessApplicationService.getBusinessApplicationKybDetails(kybId) : null,
  showNotification: false,
});

export default useBusinessApplicationKybDetailsQuery;

import React, { FC, useEffect } from 'react';
import map from 'lodash/map';

import { config, yup } from 'data';
import { businessAccountService } from 'services';
import { useAuth, useForm, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { useBusinessAccountDetailsQuery } from 'hooks/queries';
import { FeeCommissionSelect, FeeGroupSelect } from 'components/selects';
import { Form, Modal } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { BusinessAccount, FeeCommissionLevel } from 'types/models';
import { BusinessAccountUpdateDetailsParams } from 'types/services';

type FormValues = Pick<BusinessAccountUpdateDetailsParams, 'feeGroupId' | 'feeCommissionIds'>;

const initialValues: FormValues = {
  feeGroupId: null,
  feeCommissionIds: [],
};

const validationSchema = yup.object({
  feeGroupId: yup.string().notRequired().uuid().default(initialValues.feeGroupId),
  feeCommissionIds: yup.array().required().of(
    yup.string().required().uuid(),
  ),
});

type FeeModalProps = ModalBaseProps & {
  businessAccount: BusinessAccount;
};

const FeeModal: FC<FeeModalProps> = ({
  businessAccount,
  open,
  onClose,
}) => {
  const auth = useAuth();
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const businessAccountDetailsQuery = useBusinessAccountDetailsQuery(businessAccount.id);

  const invalidateBusinessAccountQueries = async () => {
    await queryInvalidate([config.BUSINESS_ACCOUNT_DETAILS_QUERY_KEY, businessAccount.id]);
    await queryInvalidate([config.BUSINESS_ACCOUNT_FEE_COMMISSIONS_QUERY_KEY, businessAccount.id]);
  };

  const updateBusinessAccountDetailsMutation = useMutation({
    mutationFn: (values: FormValues) => businessAccountService.updateBusinessAccountDetails(businessAccount.id, values),
    onSuccess: invalidateBusinessAccountQueries,
    successNotification: lang.get('businessAccount.fee.modal.success', { name: businessAccount.name }),
  });

  const handleSubmit = async (values: FormValues) => {
    await updateBusinessAccountDetailsMutation.mutateAsync(values);

    onClose();
  };

  useEffect(() => {
    if (open && businessAccountDetailsQuery.data) {
      form.setFieldsValue({
        ...businessAccountDetailsQuery.data,
        feeCommissionIds: map(businessAccountDetailsQuery.data.feeCommissions, 'id'),
      });
    }
  }, [open, form, businessAccountDetailsQuery.data]);

  const canEdit = auth.can(...businessAccountService.getBusinessAccountEditPermissions(businessAccount));

  return (
    <Modal
      title={lang.get('businessAccount.fee.modal.title', { name: businessAccount.name })}
      caption={lang.get('businessAccount.fee.modal.caption')}
      okText={lang.get('common.actions.save')}
      cancelText={!canEdit ? lang.get('common.actions.close') : null}
      okButtonProps={{ hidden: !canEdit }}
      width="small"
      open={open}
      confirmLoading={updateBusinessAccountDetailsMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        disabled={!canEdit}
        onFinish={handleSubmit}
      >
        <Form.Item name="feeGroupId" label={lang.get('common.form.feeGroup.label')}>
          <FeeGroupSelect allowClear />
        </Form.Item>
        <Form.Item name="feeCommissionIds" label={lang.get('businessAccount.fee.modal.feeCommissions.label')}>
          <FeeCommissionSelect
            params={{ level: [FeeCommissionLevel.CLIENT] }}
            placeholder={lang.get('businessAccount.fee.modal.feeCommissions.placeholder')}
            mode="multiple"
            allowClear
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FeeModal;

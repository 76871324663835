import { axios } from 'data';
import { CurrencyRate } from 'types/models';
import { CurrenciesResponse, CurrencyRateParams, CurrencyRatesParams, CurrencyRatesResponse } from 'types/services';

import { preparePaginatableParams, preparePaginatedData, prepareTimezonableParams } from './helpers';

const getCurrencies = async () => {
  const response = await axios.get<CurrenciesResponse>('/payments/admin/currency');

  return response.data.list;
};

const getCurrencyRates = async (params: CurrencyRatesParams = {}) => {
  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const response = await axios.get<CurrencyRatesResponse>('/payments/admin/currency/rate', { params });

  return preparePaginatedData<CurrencyRate>(response.data);
};

const createCurrencyRate = async (params: CurrencyRateParams) => {
  await axios.post<never>('/payments/admin/currency/rate', params);
};

const currencyService = {
  getCurrencies,
  getCurrencyRates,
  createCurrencyRate,
};

export default currencyService;

import React, { FC, useState } from 'react';
import map from 'lodash/map';

import { config } from 'data';
import { formatter } from 'helpers';
import { businessAccountService } from 'services';
import { useLang, useModal, useQueryModal } from 'hooks';
import { useBusinessAccountMembersQuery } from 'hooks/queries';
import { Plus } from 'components/icons';
import { BusinessAccountIcon, Can, TableView } from 'components/layout';
import { Button, Table } from 'components/ui';
import { Uuid } from 'types/common';
import { TableColumns } from 'types/components';
import { BusinessAccount, BusinessAccountMember } from 'types/models';

import styles from './styles.module.css';

import AddModal from './AddModal';
import ViewModal from './ViewModal';

type MembersViewProps = {
  businessAccount: BusinessAccount;
};

const MembersView: FC<MembersViewProps> = ({ businessAccount }) => {
  const lang = useLang();
  const addModal = useModal();

  const [memberId, setMemberId] = useState<Uuid>();

  const viewModal = useQueryModal(config.BUSINESS_ACCOUNT_MEMBERS_QUERY_KEY, setMemberId);

  const membersQuery = useBusinessAccountMembersQuery(businessAccount.id);

  const handleAddClick = addModal.show;

  const handleViewClick = (member: BusinessAccountMember) => () => {
    viewModal.show(member.id);
  };

  const columns: TableColumns<BusinessAccountMember> = [
    {
      className: styles.table__email,
      key: 'email',
      title: lang.get('businessAccount.member.list.email'),
      render: (_, member) => <Table.Truncate width="middle">{member.email}</Table.Truncate>,
    }, {
      key: 'name',
      title: lang.get('businessAccount.member.list.name'),
      render: (_, member) => (
        <Table.Truncate>
          {formatter.formatName(member.firstName, member.lastName) || '-'}
        </Table.Truncate>
      ),
    }, {
      key: 'role',
      title: lang.get('businessAccount.member.list.roles'),
      render: (_, member) => map(member.roles, 'name').join(', ') || '-',
    },
  ];

  const member = membersQuery.data?.find((member) => member.id === memberId);

  return (
    <TableView
      icon={<BusinessAccountIcon businessAccountType={businessAccount.type} size="large" />}
      title={lang.get('businessAccount.member.list.title', { name: businessAccount.name })}
      actions={(
        <Can permissions={businessAccountService.getBusinessAccountEditPermissions(businessAccount)}>
          <Button icon={<Plus />} onClick={handleAddClick}>
            {lang.get('businessAccount.member.list.add')}
          </Button>
        </Can>
      )}
    >

      <Table<BusinessAccountMember>
        columns={columns}
        dataSource={membersQuery.data ?? []}
        rowKey={(member) => member.id}
        loading={membersQuery.isLoading}
        clickable
        onRow={(member) => ({ onClick: handleViewClick(member) })}
      />

      <AddModal
        businessAccount={businessAccount}
        open={addModal.open}
        onClose={addModal.hide}
      />

      {member && (
        <ViewModal
          businessAccount={businessAccount}
          member={member}
          open={viewModal.open}
          onClose={viewModal.hide}
        />
      )}

    </TableView>
  );
};

export default MembersView;

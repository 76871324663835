import { config } from 'data';
import { businessAccountService } from 'services';
import { useQuery } from 'hooks';
import { Uuid } from 'types/common';

const useAllBusinessAccountAdjustmentCurrenciesQuery = (id?: Uuid) => useQuery({
  queryKey: [config.BUSINESS_ACCOUNT_ADJUSTMENT_CURRENCIES_QUERY_KEY, 'all', id],
  queryFn: () => id ? businessAccountService.getBusinessAccountAdjustmentCurrencies(id) : null,
});

export default useAllBusinessAccountAdjustmentCurrenciesQuery;

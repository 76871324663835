import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import truncate from 'lodash/truncate';

import { config, moment } from 'data';
import { formatter } from 'helpers';
import { transactionService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useAuth, useForm, useFormWatch, useLang, useQueryModal, useTable, useTableQuery } from 'hooks';
import { useCountryFormatter, useTransactionFormatter } from 'hooks/formatters';
import { useTransactionQuery, useTransactionsQuery } from 'hooks/queries';
import { TopUp } from 'components/icons';
import { BusinessAccountIcon, TableView, TransactionStatus } from 'components/layout';
import { TransactionModal } from 'components/modals';
import { CountrySelect, CurrencySelect, ProviderSelect } from 'components/selects';
import { Button, Checkbox, DateRangePicker, Flex, Form, Radio, Select, Space, Table } from 'components/ui';
import { Uuid } from 'types/common';
import { ExportColumns, TableColumns } from 'types/components';
import { TransactionsParams } from 'types/services';
import { TransactionsViewFilters } from 'types/views';

import {
  BusinessAccount,
  BusinessAccountType,
  Transaction,
  TransactionDirection as ETransactionDirection,
  TransactionPayoutMethod,
  TransactionStatus as ETransactionStatus,
  TransactionType,
  TransactionTypeCategory,
  UserPermission,
} from 'types/models';

import styles from './styles.module.css';

const EXPORT_FILE_NAME = 'transactions';

type TableParams = TransactionsViewFilters & {
  category?: TransactionTypeCategory;
};

const initialTableParams: TableParams = {
  //
};

const typeCategories = transactionService.getTransactionTypeCategories();
const typeDirections = transactionService.getTransactionTypeDirections();

type TransactionsViewProps = {
  businessAccount?: BusinessAccount;
};

const TransactionsView: FC<TransactionsViewProps> = ({ businessAccount }) => {
  const auth = useAuth();
  const filtersForm = useForm<TableParams>();
  const lang = useLang();
  const table = useTable<Transaction, TableParams>([config.TRANSACTIONS_QUERY_KEY, businessAccount?.id], initialTableParams);
  const countryFormatter = useCountryFormatter();
  const transactionFormatter = useTransactionFormatter();

  const currentCategoryFilter = useFormWatch('category', filtersForm);
  const currentDirectionFilter = useFormWatch('direction', filtersForm);
  const currentTransactionTypeFilter = useFormWatch('transactionType', filtersForm);

  const [transactionId, setTransactionId] = useState<Uuid>();

  const modal = useQueryModal(config.TRANSACTIONS_QUERY_KEY, setTransactionId);

  const transactionsParams: TransactionsParams = {
    clientId: businessAccount?.id,
    page: table.page,
    search: table.params.search || undefined,
    providerId: table.params.providerId || undefined,
    country: table.params.country || undefined,
    destinationCurrency: table.params.destinationCurrency || undefined,
    direction: table.params.direction || undefined,
    originCurrency: table.params.originCurrency || undefined,
    payoutMethod: table.params.payoutMethod || undefined,
    status: table.params.status || undefined,
    transactionType: table.params.transactionType || undefined,
    dateFrom: (table.params.dates && table.params.dates[0]) || undefined,
    dateTo: (table.params.dates && table.params.dates[1]) || undefined,
  };

  const transactionsQuery = useTransactionsQuery(transactionsParams);
  const transactionQuery = useTransactionQuery(transactionId);

  const isTransactionTypeFilterAvailable = useCallback((type: TransactionType) => Boolean(
    (!currentCategoryFilter || typeCategories[type] === currentCategoryFilter)
    && (!currentDirectionFilter || typeDirections[type].includes(currentDirectionFilter)),
  ), [currentCategoryFilter, currentDirectionFilter]);

  const handleCreateClick = () => {
    modal.show();
  };

  const handleViewClick = (transaction: Transaction) => () => {
    modal.show(transaction.id);
  };

  useTableQuery(table, transactionsQuery);

  useEffect(() => {
    if (filtersForm.isFieldsTouched(['category', 'direction'])) {
      const transactionType = Object.entries(typeCategories)
        .filter(([type, category]) => isTransactionTypeFilterAvailable(type as TransactionType) && category === currentCategoryFilter)
        .map(([type]) => type) as TransactionType[];

      filtersForm.setFieldsValue({ transactionType });
    }
  }, [filtersForm, currentCategoryFilter, currentDirectionFilter, isTransactionTypeFilterAvailable]);

  useEffect(() => {
    if (filtersForm.isFieldTouched('transactionType') && !currentTransactionTypeFilter?.length) {
      const transactionType = Object.entries(typeCategories)
        .filter(([, category]) => category === currentCategoryFilter)
        .map(([type]) => type) as TransactionType[];

      filtersForm.setFieldsValue({ transactionType });
    }
  }, [filtersForm, currentCategoryFilter, currentTransactionTypeFilter]);

  const getTransactionUpdateDate = (transaction: Transaction) => transaction.statusChangedAt ?? transaction.createdAt;

  const formatTransactionBusinessAccount = (transaction: Transaction) => transaction.client.name;

  const formatTransactionProvider = (transaction: Transaction) => transaction.provider?.name ?? '-';

  const exportColumns: ExportColumns<Transaction> = useMemo(() => [
    {
      title: lang.get('transaction.list.creationDate'),
      render: (transaction) => formatter.formatDate(transaction.createdAt),
    }, {
      title: lang.get('transaction.list.creationTime'),
      render: (transaction) => formatter.formatTime(transaction.createdAt),
    }, {
      title: lang.get('transaction.list.updateDate'),
      render: (transaction) => formatter.formatDate(getTransactionUpdateDate(transaction)),
    }, {
      title: lang.get('transaction.list.updateTime'),
      render: (transaction) => formatter.formatTime(getTransactionUpdateDate(transaction)),
    }, {
      title: lang.get('transaction.list.transactionId'),
      render: (transaction) => transaction.id,
    }, {
      title: lang.get('transaction.list.flowId'),
      render: (transaction) => transaction.flowId ?? '-',
    }, {
      title: lang.get('transaction.list.externalId'),
      render: (transaction) => transaction.externalId ?? '-',
    }, {
      title: lang.get('transaction.list.providerReferenceId'),
      render: (transaction) => transaction.providerReferenceId ?? '-',
    }, {
      title: lang.get('transaction.list.clientReferenceId'),
      render: (transaction) => transaction.clientReferenceId ?? '-',
    }, {
      title: lang.get('transaction.list.accountId'),
      render: (transaction) => transaction.virtualAccount?.id ?? '-',
    }, {
      title: lang.get('transaction.list.accountNumber'),
      render: (transaction) => transaction.virtualAccount?.externalId ?? '-',
    }, {
      title: lang.get('transaction.list.businessAccount'),
      render: (transaction) => formatTransactionBusinessAccount(transaction),
    }, {
      title: lang.get('transaction.list.beneficiaryName'),
      render: (transaction) => transaction.beneficiaryDetails?.name ?? '-',
    }, {
      title: lang.get('transaction.list.beneficiaryIdentifier'),
      render: (transaction) => transaction.beneficiaryDetails?.proxyType && transaction.beneficiaryDetails?.proxyValue
        ? `${transaction.beneficiaryDetails?.proxyType} ${transaction.beneficiaryDetails?.proxyValue}`
        : '-',
    }, {
      title: lang.get('transaction.list.bankId'),
      render: (transaction) => transaction.beneficiaryDetails?.bankId ?? '-',
    }, {
      title: lang.get('transaction.list.bankName'),
      render: (transaction) => transaction.beneficiaryDetails?.bankName ?? '-',
    }, {
      title: lang.get('transaction.list.bankCode'),
      render: (transaction) => transaction.beneficiaryDetails?.bankCode ?? '-',
    }, {
      title: lang.get('transaction.list.bankCountry'),
      render: (transaction) => countryFormatter.format(transaction.beneficiaryDetails?.bankCountryCode, '-'),
    }, {
      title: lang.get('transaction.list.bankBranch'),
      render: (transaction) => transaction.beneficiaryDetails?.branchCode ?? '-',
    }, {
      title: lang.get('transaction.list.bankSwift'),
      render: (transaction) => transaction.beneficiaryDetails?.swiftBic ?? '-',
    }, {
      title: lang.get('transaction.list.bankAccount'),
      render: (transaction) => transaction.beneficiaryDetails?.accountNo ?? '-',
    }, {
      title: lang.get('transaction.list.country'),
      render: (transaction) => countryFormatter.format(transaction.country, '-'),
    }, {
      title: lang.get('transaction.list.provider'),
      render: (transaction) => formatTransactionProvider(transaction),
    }, {
      title: lang.get('transaction.list.type'),
      render: (transaction) => transactionFormatter.formatType(transaction.transactionType),
    }, {
      title: lang.get('transaction.list.payoutMethod'),
      render: (transaction) => transaction.payoutMethod
        ? transactionFormatter.formatPayoutMethod(transaction.payoutMethod)
        : '-',
    }, {
      title: lang.get('transaction.list.originAmount'),
      render: (transaction) => formatter.formatNumber(transaction.originAmount),
    }, {
      title: lang.get('transaction.list.originCurrency'),
      render: (transaction) => transaction.originCurrency,
    }, {
      title: lang.get('transaction.list.destinationAmount'),
      render: (transaction) => formatter.formatNumber(transaction.destinationAmount),
    }, {
      title: lang.get('transaction.list.destinationCurrency'),
      render: (transaction) => transaction.destinationCurrency,
    }, {
      title: lang.get('transaction.list.transactionFee'),
      render: (transaction) => formatter.formatNumber(transaction.transactionFee),
    }, {
      title: lang.get('transaction.list.fxMarkup'),
      render: (transaction) => formatter.formatNumber(transaction.fxMarkupFee),
    }, {
      title: lang.get('transaction.list.providerFee'),
      render: (transaction) => formatter.formatNumber(transaction.providerFee),
    }, {
      title: lang.get('transaction.list.incoming'),
      render: (transaction) => formatter.formatNumber(transaction.previousBalance),
    }, {
      title: lang.get('transaction.list.outgoing'),
      render: (transaction) => formatter.formatNumber(transaction.balance),
    }, {
      title: lang.get('transaction.list.status'),
      render: (transaction) => transactionFormatter.formatStatus(transaction.status),
    }, {
      title: lang.get('transaction.list.paymentDetails'),
      render: (transaction) => transaction.metadata?.paymentDetails ?? '-',
    }, {
      title: lang.get('transaction.list.additionalInfo'),
      render: (transaction) => transaction.metadata?.additionalInfo ?? '-',
    }, {
      title: lang.get('transaction.list.notes'),
      render: (transaction) => transaction.notes ?? '-',
    }, {
      title: lang.get('transaction.list.securityDeviceId'),
      render: (transaction) => transaction.securityInfo?.deviceId ?? '-',
    }, {
      title: lang.get('transaction.list.securityDeviceName'),
      render: (transaction) => transaction.securityInfo?.deviceName ?? '-',
    }, {
      title: lang.get('transaction.list.securityOs'),
      render: (transaction) => transaction.securityInfo?.os ?? '-',
    }, {
      title: lang.get('transaction.list.securityPhysical'),
      render: (transaction) => typeof transaction.securityInfo?.isPhysical === 'boolean'
        ? transaction.securityInfo.isPhysical ? lang.get('common.actions.yes') : lang.get('common.actions.no')
        : '-',
    }, {
      title: lang.get('transaction.list.securityIps'),
      render: (transaction) => transaction.securityInfo?.ips?.join(', ') ?? '-',
    }, {
      title: lang.get('transaction.list.securityForwardedFor'),
      render: (transaction) => transaction.securityInfo?.xForwardedFor?.join(', ') ?? '-',
    }, {
      title: lang.get('transaction.list.securityUserAgent'),
      render: (transaction) => transaction.securityInfo?.userAgent ?? '-',
    },
  ], [lang, countryFormatter, transactionFormatter]);

  const tableColumns: TableColumns<Transaction> = [
    {
      className: styles.table__date,
      key: 'date',
      title: lang.get('transaction.list.date'),
      render: (_, transaction) => (
        <span title={formatter.formatDateTime(getTransactionUpdateDate(transaction))}>
          {formatter.formatDate(getTransactionUpdateDate(transaction))}
        </span>
      ),
    }, {
      className: styles.table__id,
      key: 'id',
      title: lang.get('transaction.list.id'),
      render: (_, transaction) => (
        <span title={transaction.id}>
          {truncate(transaction.id, { length: config.TABLE_ID_MAX_LENGTH })}
        </span>
      ),
    }, {
      key: 'businessAccount',
      title: lang.get('transaction.list.businessAccount'),
      hidden: Boolean(businessAccount),
      render: (_, transaction) => (
        <Table.Truncate title={formatTransactionBusinessAccount(transaction)}>
          <BusinessAccountIcon businessAccountType={transaction.client.type} />
          <span>{formatTransactionBusinessAccount(transaction)}</span>
        </Table.Truncate>
      ),
    }, {
      key: 'provider',
      title: lang.get('transaction.list.provider'),
      render: (_, transaction) => formatTransactionProvider(transaction),
    }, {
      className: styles.table__balance,
      key: 'incoming',
      title: lang.get('transaction.list.incoming'),
      render: (_, transaction) => formatter.formatCurrency(transaction.previousBalance, transaction.originCurrency),
    }, {
      className: styles.table__amount,
      key: 'originAmount',
      title: lang.get('transaction.list.originAmount'),
      render: (_, transaction) => formatter.formatCurrency(transaction.originAmount, transaction.originCurrency),
    }, {
      className: styles.table__amount,
      key: 'destinationAmount',
      title: lang.get('transaction.list.destinationAmount'),
      render: (_, transaction) => formatter.formatCurrency(transaction.destinationAmount, transaction.destinationCurrency),
    }, {
      className: styles.table__balance,
      key: 'fees',
      title: lang.get('transaction.list.fees'),
      render: (_, transaction) => formatter.formatCurrency(transaction.transactionFee, transaction.originCurrency),
    }, {
      className: styles.table__balance,
      key: 'outgoing',
      title: lang.get('transaction.list.outgoing'),
      render: (_, transaction) => formatter.formatCurrency(transaction.balance, transaction.originCurrency),
    }, {
      key: 'status',
      title: lang.get('transaction.list.status'),
      render: (_, transaction) => <TransactionStatus status={transaction.status} />,
    },
  ];

  const availableTransactionTypesFilter = Object.values(TransactionType).filter(isTransactionTypeFilterAvailable);

  return (
    <TableView
      title={lang.get('transaction.list.title')}
      actions={(
        <Flex gap="small" wrap="wrap">
          <TableView.ExportButton<Transaction>
            table={table}
            type="default"
            fileName={[
              EXPORT_FILE_NAME,
              businessAccount?.name,
              table.params.dates && table.params.dates[0] && moment(table.params.dates[0]).format(config.DATE_RAW_FORMAT),
              table.params.dates && table.params.dates[1] && moment(table.params.dates[1]).format(config.DATE_RAW_FORMAT),
            ].filter(Boolean).join('-')}
            columns={exportColumns}
            fetchData={() => fetchPaginatedResponseFully(transactionService.getTransactions, transactionsParams)}
          />
          <Button
            icon={<TopUp />}
            hidden={(() => {
              if (auth.can(UserPermission.TRX_UPDATE)) {
                return false;
              }

              if (businessAccount) {
                return auth.cannot(
                  businessAccount.type === BusinessAccountType.INDIVIDUAL
                    ? UserPermission.INDIVIDUALS_TRX_UPDATE
                    : UserPermission.BA_TRX_UPDATE,
                );
              }

              return auth.cannot(UserPermission.BA_TRX_UPDATE, UserPermission.INDIVIDUALS_TRX_UPDATE);
            })()}
            onClick={handleCreateClick}
          >
            {lang.get('transaction.list.create')}
          </Button>
        </Flex>
      )}
    >

      <TableView.Filters<TableParams>
        form={filtersForm}
        initialValues={initialTableParams}
        values={table.params}
        withSearch
        onSubmit={table.setParams}
      >
        <Form.Item name="category" label={lang.get('common.filters.category')}>
          <Radio.Group
            options={[
              ...[{
                value: undefined,
                label: lang.get('common.actions.all'),
              }],
              ...Object.values(TransactionTypeCategory).map((category) => ({
                value: category,
                label: transactionFormatter.formatTypeCategory(category),
              })),
            ]}
            optionType="button"
            buttonStyle="solid"
            block
          />
        </Form.Item>
        <Form.Item name="providerId" label={lang.get('common.filters.provider')}>
          <ProviderSelect placeholder={lang.get('common.actions.all')} allowClear />
        </Form.Item>
        <Form.Item name="country" label={lang.get('common.filters.country')}>
          <CountrySelect placeholder={lang.get('common.actions.all')} allowClear />
        </Form.Item>
        <Form.Item name="originCurrency" label={lang.get('transaction.filters.originCurrency')}>
          <CurrencySelect placeholder={lang.get('common.actions.all')} allowClear />
        </Form.Item>
        <Form.Item name="destinationCurrency" label={lang.get('transaction.filters.destinationCurrency')}>
          <CurrencySelect placeholder={lang.get('common.actions.all')} allowClear />
        </Form.Item>
        <Form.Item name="payoutMethod" label={lang.get('transaction.filters.payoutMethod')}>
          <Select
            placeholder={lang.get('common.actions.all')}
            options={Object.values(TransactionPayoutMethod).map((method) => ({
              value: method,
              label: transactionFormatter.formatPayoutMethod(method),
            }))}
            allowClear
          />
        </Form.Item>
        <Form.Item name="direction" label={lang.get('transaction.filters.direction')}>
          <Select
            placeholder={lang.get('common.actions.all')}
            options={Object.values(ETransactionDirection).map((direction) => ({
              value: direction,
              label: transactionFormatter.formatDirection(direction),
            }))}
            allowClear
          />
        </Form.Item>
        <Form.Divider hidden={!availableTransactionTypesFilter.length} />
        <Form.Item
          name="transactionType"
          label={lang.get('feeCommission.filters.transactionType')}
          hidden={!availableTransactionTypesFilter.length}
        >
          <Checkbox.Group>
            <Space direction="vertical">
              {availableTransactionTypesFilter.map((type) => (
                <Checkbox key={type} value={type}>
                  {transactionFormatter.formatType(type)}
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
        </Form.Item>
        <Form.Divider />
        <Form.Item name="status" label={lang.get('common.filters.status')}>
          <Checkbox.Group>
            <Space direction="vertical">
              {Object.values(ETransactionStatus).map((status) => (
                <Checkbox key={status} value={status}>
                  {transactionFormatter.formatStatus(status)}
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
        </Form.Item>
        <Form.Divider />
        <Form.Item name="dates" label={lang.get('common.filters.date')}>
          <DateRangePicker maxDate={moment()} allowEmpty />
        </Form.Item>
      </TableView.Filters>

      <TableView.Table<Transaction>
        table={table}
        columns={tableColumns}
        rowKey={(transaction) => transaction.id}
        loading={transactionsQuery.isLoading}
        clickable
        onRow={(transaction) => ({ onClick: handleViewClick(transaction) })}
      />

      <TransactionModal
        businessAccount={businessAccount}
        transaction={transactionQuery.data}
        open={!transactionQuery.isLoading && modal.open}
        onClose={modal.hide}
      />

    </TableView>
  );
};

export default TransactionsView;

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { config } from 'data';
import { formatter } from 'helpers';
import { transactionService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useForm, useFormWatch, useLang, useQuery, useQueryModal, useTable, useTableQuery } from 'hooks';
import { useCorridorFormatter, useTransactionFormatter } from 'hooks/formatters';
import { useFeeCommissionQuery } from 'hooks/queries';
import { Plus } from 'components/icons';
import { ActivityStatus, Can, TableView } from 'components/layout';
import { CountrySelect, CurrencySelect } from 'components/selects';
import { Button, Checkbox, Flex, Form, Radio, Select, Space, Table } from 'components/ui';
import { Uuid } from 'types/common';
import { ExportColumns, TableColumns, TableViewProps } from 'types/components';
import { TableKey } from 'types/hooks';
import { FeeCommissionsParams, PaginatedData } from 'types/services';

import {
  ActivityStatus as TActivityStatus,
  FeeCommission,
  FeeCommissionLevel,
  TransactionDirection,
  TransactionType,
  TransactionTypeCategory,
  UserPermission,
} from 'types/models';

import styles from './styles.module.css';

import Modal from './Modal';

const EXPORT_FILE_NAME = 'fee-configurations';

type TableParams = FeeCommissionsParams & {
  category?: TransactionTypeCategory;
  status?: TActivityStatus;
};

const initialTableParams: TableParams = {
  //
};

const transactionTypeCategories = transactionService.getTransactionTypeCategories();
const transactionTypeDirections = transactionService.getTransactionTypeDirections();

type FeeCommissionsViewProps = Pick<TableViewProps, 'icon' | 'title' | 'caption'> & {
  tableKey: TableKey;
  fetchData: (params?: FeeCommissionsParams) => Promise<PaginatedData<FeeCommission>>;
};

const FeeCommissionsView: FC<FeeCommissionsViewProps> = ({
  tableKey,
  fetchData,
  ...props
}) => {
  const filtersForm = useForm<TableParams>();
  const lang = useLang();
  const table = useTable<FeeCommission, TableParams>(tableKey, initialTableParams);
  const corridorFormatter = useCorridorFormatter();
  const transactionFormatter = useTransactionFormatter();

  const currentCategoryFilter = useFormWatch('category', filtersForm);
  const currentDirectionFilter = useFormWatch('direction', filtersForm);
  const currentTransactionTypeFilter = useFormWatch('transactionType', filtersForm);

  const [feeCommissionId, setFeeCommissionId] = useState<Uuid>();

  const modal = useQueryModal(config.FEE_COMMISSIONS_QUERY_KEY, setFeeCommissionId);

  const feeCommissionQuery = useFeeCommissionQuery(feeCommissionId);

  const feeCommissionsParams: FeeCommissionsParams = {
    page: table.page,
    level: table.params.level || undefined,
    transactionType: table.params.transactionType || undefined,
    direction: table.params.direction || undefined,
    country: table.params.country || undefined,
    currency: table.params.currency || undefined,
    active: table.params.status ? table.params.status === TActivityStatus.ACTIVE : undefined,
  };

  const feeCommissionsQuery = useQuery({
    queryKey: [...tableKey, fetchData, feeCommissionsParams],
    queryFn: () => fetchData(feeCommissionsParams),
  });

  const isTransactionTypeFilterAvailable = useCallback((type: TransactionType) => Boolean(
    (!currentCategoryFilter || transactionTypeCategories[type] === currentCategoryFilter)
    && (!currentDirectionFilter || transactionTypeDirections[type].includes(currentDirectionFilter)),
  ), [currentCategoryFilter, currentDirectionFilter]);

  const handleCreateClick = () => {
    modal.show();
  };

  const handleViewClick = (feeCommission: FeeCommission) => () => {
    modal.show(feeCommission.id);
  };

  useTableQuery(table, feeCommissionsQuery);

  useEffect(() => {
    if (filtersForm.isFieldsTouched(['category', 'direction'])) {
      const transactionType = Object.entries(transactionTypeCategories)
        .filter(([type, category]) => isTransactionTypeFilterAvailable(type as TransactionType) && category === currentCategoryFilter)
        .map(([type]) => type) as TransactionType[];

      filtersForm.setFieldsValue({ transactionType });
    }
  }, [filtersForm, currentCategoryFilter, currentDirectionFilter, isTransactionTypeFilterAvailable]);

  useEffect(() => {
    if (filtersForm.isFieldTouched('transactionType') && !currentTransactionTypeFilter?.length) {
      const transactionType = Object.entries(transactionTypeCategories)
        .filter(([, category]) => category === currentCategoryFilter)
        .map(([type]) => type) as TransactionType[];

      filtersForm.setFieldsValue({ transactionType });
    }
  }, [filtersForm, currentCategoryFilter, currentTransactionTypeFilter]);

  const formatFeeCommissionName = (feeCommission: FeeCommission) => feeCommission.name;

  const formatFeeCommissionLevel = useCallback((feeCommission: FeeCommission) => lang.get(`feeCommission.levels.${feeCommission.level.toLowerCase()}`), [lang]);

  const formatFeeCommissionTransactionType = useCallback((feeCommission: FeeCommission) => {
    let label = transactionFormatter.formatType(feeCommission.corridor.type);

    if (feeCommission.feeFrequency) {
      label += ` (${lang.get(`feeCommission.frequencies.${feeCommission.feeFrequency.toLowerCase()}`)})`;
    }

    return label;
  }, [lang, transactionFormatter]);

  const formatFeeCommissionCorridor = useCallback((feeCommission: FeeCommission) => corridorFormatter.format(feeCommission.corridor.currencyCorridor.country, feeCommission.corridor.currencyCorridor.currency), [corridorFormatter]);

  const formatFeeCommissionDirection = useCallback((feeCommission: FeeCommission) => {
    return feeCommission.direction
      ? transactionFormatter.formatDirection(feeCommission.direction)
      : '-';
  }, [transactionFormatter]);

  const formatFeeCommissionFrequency = useCallback((feeCommission: FeeCommission) => {
    return feeCommission.feeFrequency
      ? lang.get(`feeCommission.frequencies.${feeCommission.feeFrequency.toLowerCase()}`)
      : '-';
  }, [lang]);

  const formatFeeCommissionTransactionFee = (feeCommission: FeeCommission) => {
    const fixedFee = feeCommission.fixedTransactionFee && formatter.formatCurrency(feeCommission.fixedTransactionFee, feeCommission.transactionFeeCurrency);
    const percentFee = feeCommission.percentTransactionFee && formatter.formatPercent(feeCommission.percentTransactionFee, config.FEE_COMMISSION_PERCENT_PRECISION);

    return [fixedFee, percentFee].filter(Boolean).join(' + ') || '-';
  };

  const formatFeeCommissionMinTransactionFeeAmount = (feeCommission: FeeCommission) => formatter.formatCurrency(feeCommission.percentTransactionFee ? feeCommission.minTransactionFeeAmount : feeCommission.fixedTransactionFee, feeCommission.transactionFeeCurrency);

  const formatFeeCommissionMaxTransactionFeeAmount = (feeCommission: FeeCommission) => formatter.formatCurrency(feeCommission.percentTransactionFee ? feeCommission.maxTransactionFeeAmount : feeCommission.fixedTransactionFee, feeCommission.transactionFeeCurrency);

  const formatFeeCommissionFxMarkup = (feeCommission: FeeCommission) => formatter.formatPercent(feeCommission.fxMarkup);

  const exportColumns: ExportColumns<FeeCommission> = useMemo(() => [
    {
      title: lang.get('feeCommission.list.name'),
      render: (feeCommission) => formatFeeCommissionName(feeCommission),
    }, {
      title: lang.get('feeCommission.list.level'),
      render: (feeCommission) => formatFeeCommissionLevel(feeCommission),
    }, {
      title: lang.get('feeCommission.list.transactionType'),
      render: (feeCommission) => formatFeeCommissionTransactionType(feeCommission),
    }, {
      title: lang.get('feeCommission.list.direction'),
      render: (feeCommission) => formatFeeCommissionDirection(feeCommission),
    }, {
      title: lang.get('feeCommission.list.frequency'),
      render: (feeCommission) => formatFeeCommissionFrequency(feeCommission),
    }, {
      title: lang.get('feeCommission.list.corridor'),
      render: (feeCommission) => formatFeeCommissionCorridor(feeCommission),
    }, {
      title: lang.get('feeCommission.list.transactionFee'),
      render: (feeCommission) => formatFeeCommissionTransactionFee(feeCommission),
    }, {
      title: lang.get('feeCommission.list.minTransactionFeeAmount'),
      render: (feeCommission) => formatFeeCommissionMinTransactionFeeAmount(feeCommission),
    }, {
      title: lang.get('feeCommission.list.maxTransactionFeeAmount'),
      render: (feeCommission) => formatFeeCommissionMaxTransactionFeeAmount(feeCommission),
    }, {
      title: lang.get('feeCommission.list.fxMarkup'),
      render: (feeCommission) => formatFeeCommissionFxMarkup(feeCommission),
    }, {
      title: lang.get('feeCommission.list.startDate'),
      render: (feeCommission) => feeCommission.startDate ? formatter.formatDate(feeCommission.startDate) : '-',
    }, {
      title: lang.get('feeCommission.list.endDate'),
      render: (feeCommission) => feeCommission.endDate ? formatter.formatDate(feeCommission.endDate) : '-',
    }, {
      title: lang.get('feeCommission.list.status'),
      render: (feeCommission) => lang.get(feeCommission.active ? 'common.activityStatuses.active' : 'common.activityStatuses.inactive'),
    },
  ], [
    lang,
    formatFeeCommissionLevel,
    formatFeeCommissionTransactionType,
    formatFeeCommissionDirection,
    formatFeeCommissionFrequency,
    formatFeeCommissionCorridor,
  ]);

  const tableColumns: TableColumns<FeeCommission> = [
    {
      className: styles.table__name,
      key: 'name',
      title: lang.get('feeCommission.list.name'),
      render: (_, feeCommission) => <Table.Truncate>{formatFeeCommissionName(feeCommission)}</Table.Truncate>,
    }, {
      key: 'level',
      title: lang.get('feeCommission.list.level'),
      render: (_, feeCommission) => formatFeeCommissionLevel(feeCommission),
    }, {
      key: 'transactionType',
      title: lang.get('feeCommission.list.transactionType'),
      render: (_, feeCommission) => formatFeeCommissionTransactionType(feeCommission),
    }, {
      key: 'direction',
      title: lang.get('feeCommission.list.direction'),
      render: (_, feeCommission) => formatFeeCommissionDirection(feeCommission),
    }, {
      key: 'corridor',
      title: lang.get('feeCommission.list.corridor'),
      render: (_, feeCommission) => formatFeeCommissionCorridor(feeCommission),
    }, {
      className: styles.table__amount,
      key: 'transactionFee',
      title: lang.get('feeCommission.list.transactionFee'),
      render: (_, feeCommission) => formatFeeCommissionTransactionFee(feeCommission),
    }, {
      className: styles.table__amount,
      key: 'minTransactionFeeAmount',
      title: lang.get('feeCommission.list.minTransactionFeeAmount'),
      render: (_, feeCommission) => formatFeeCommissionMinTransactionFeeAmount(feeCommission),
    }, {
      className: styles.table__amount,
      key: 'maxTransactionFeeAmount',
      title: lang.get('feeCommission.list.maxTransactionFeeAmount'),
      render: (_, feeCommission) => formatFeeCommissionMaxTransactionFeeAmount(feeCommission),
    }, {
      className: styles.table__amount,
      key: 'fxMarkup',
      title: lang.get('feeCommission.list.fxMarkup'),
      render: (_, feeCommission) => formatFeeCommissionFxMarkup(feeCommission),
    }, {
      className: styles.table__period,
      key: 'period',
      title: lang.get('feeCommission.list.period'),
      render: (_, feeCommission) => {
        if (feeCommission.startDate && feeCommission.endDate) {
          return (
            <>
              <span>{formatter.formatDate(feeCommission.startDate)}{' - '}</span>
              {' '}
              <span>{formatter.formatDate(feeCommission.endDate)}</span>
            </>
          );
        }

        return '-';
      },
    }, {
      key: 'status',
      title: lang.get('feeCommission.list.status'),
      render: (_, feeCommission) => <ActivityStatus active={feeCommission.active} />,
    },
  ];

  const availableTransactionTypesFilter = Object.values(TransactionType).filter(isTransactionTypeFilterAvailable);

  return (
    <TableView
      actions={(
        <Flex gap="small" wrap="wrap">
          <TableView.ExportButton<FeeCommission>
            table={table}
            type="default"
            fileName={EXPORT_FILE_NAME}
            columns={exportColumns}
            fetchData={() => fetchPaginatedResponseFully(fetchData, feeCommissionsParams)}
          />
          <Can permissions={[UserPermission.FEE_COMMISSIONS_UPDATE]}>
            <Button icon={<Plus />} onClick={handleCreateClick}>
              {lang.get('feeCommission.list.create')}
            </Button>
          </Can>
        </Flex>
      )}
      {...props}
    >

      <TableView.Filters<TableParams>
        form={filtersForm}
        initialValues={initialTableParams}
        values={table.params}
        onSubmit={table.setParams}
      >
        <Form.Item name="category" label={lang.get('common.filters.category')}>
          <Radio.Group
            options={[
              ...[{
                value: undefined,
                label: lang.get('common.actions.all'),
              }],
              ...Object.values(TransactionTypeCategory).map((category) => ({
                value: category,
                label: transactionFormatter.formatTypeCategory(category),
              })),
            ]}
            optionType="button"
            buttonStyle="solid"
            block
          />
        </Form.Item>
        <Form.Item name="country" label={lang.get('common.filters.country')}>
          <CountrySelect
            placeholder={lang.get('common.actions.all')}
            allowClear
            includeAny
            disabled={Boolean(currentTransactionTypeFilter?.length && !currentTransactionTypeFilter.some(transactionService.isTransactionTypeSupportsCountry))}
          />
        </Form.Item>
        <Form.Item name="currency" label={lang.get('common.filters.currency')}>
          <CurrencySelect
            placeholder={lang.get('common.actions.all')}
            allowClear
            includeAny
          />
        </Form.Item>
        <Form.Item name="direction" label={lang.get('feeCommission.filters.direction')}>
          <Select
            placeholder={lang.get('common.actions.all')}
            options={Object.values(TransactionDirection).map((direction) => ({
              value: direction,
              label: transactionFormatter.formatDirection(direction),
            }))}
            allowClear
          />
        </Form.Item>
        <Form.Divider />
        <Form.Item name="level" label={lang.get('feeCommission.filters.level')}>
          <Checkbox.Group>
            <Space direction="vertical">
              {Object.values(FeeCommissionLevel).map((level) => (
                <Checkbox key={level} value={level}>
                  {lang.get(`feeCommission.levels.${level.toLowerCase()}`)}
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
        </Form.Item>
        <Form.Divider hidden={!availableTransactionTypesFilter.length} />
        <Form.Item
          name="transactionType"
          label={lang.get('feeCommission.filters.transactionType')}
          hidden={!availableTransactionTypesFilter.length}
        >
          <Checkbox.Group>
            <Space direction="vertical">
              {availableTransactionTypesFilter.map((type) => (
                <Checkbox key={type} value={type}>
                  {transactionFormatter.formatType(type)}
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
        </Form.Item>
        <Form.Divider />
        <Form.Item name="status" label={lang.get('common.filters.status')}>
          <Select
            placeholder={lang.get('common.actions.all')}
            options={Object.values(TActivityStatus).map((status) => ({
              value: status,
              label: lang.get(`common.activityStatuses.${status.toLowerCase()}`),
            }))}
            allowClear
          />
        </Form.Item>
      </TableView.Filters>

      <TableView.Table<FeeCommission>
        table={table}
        columns={tableColumns}
        rowKey={(feeCommission) => feeCommission.id}
        loading={feeCommissionsQuery.isLoading}
        clickable
        onRow={(feeCommission) => ({ onClick: handleViewClick(feeCommission) })}
      />

      <Modal
        feeCommission={feeCommissionQuery.data}
        open={!feeCommissionQuery.isLoading && modal.open}
        onClose={modal.hide}
      />

    </TableView>
  );
};

export default FeeCommissionsView;

import React, { FC } from 'react';
import { Link } from 'react-router';

import { config } from 'data';
import { formatter, url } from 'helpers';
import { useLang } from 'hooks';
import { useCountryFormatter, useTransactionFormatter } from 'hooks/formatters';
import { BusinessAccountBalance, TransactionStatus } from 'components/layout';
import { Alert, Collapse, Descriptions, Form, Modal, Paragraph } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { BusinessAccount, Transaction } from 'types/models';
import { TransactionModalSection } from 'types/views';

import styles from './styles.module.css';

import Title from './Title';

type ViewTransactionModalProps = ModalBaseProps & {
  businessAccount: BusinessAccount;
  transaction: Transaction;
};

const ViewTransactionModal: FC<ViewTransactionModalProps> = ({
  businessAccount,
  transaction,
  open,
  onClose,
}) => {
  const lang = useLang();
  const countryFormatter = useCountryFormatter();
  const transactionFormatter = useTransactionFormatter();

  const transactionBeneficiary = transaction.beneficiaryDetails;
  const transactionMetadata = transaction.metadata;
  const transactionSecurityInfo = transaction.securityInfo;

  return (
    <Modal
      title={<Title businessAccount={businessAccount} transaction={transaction} />}
      caption={<BusinessAccountBalance businessAccount={businessAccount} />}
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      open={open}
      onCancel={onClose}
    >

      <Form.Item hidden={!transaction.errorMsg}>
        <Alert type="error" message={transaction.errorMsg} />
      </Form.Item>

      <Collapse
        defaultActiveKey={[
          TransactionModalSection.BENEFICIARY,
          TransactionModalSection.META,
          TransactionModalSection.PAYMENT,
        ]}
        items={[{
          key: TransactionModalSection.BENEFICIARY,
          label: lang.get('transaction.modal.sections.beneficiary'),
          children: (
            <>

              <Descriptions
                items={[{
                  label: lang.get('transaction.modal.beneficiaryName.label'),
                  children: transactionBeneficiary?.name ?? '-',
                }, {
                  label: lang.get('transaction.modal.beneficiaryIdentifier.label'),
                  children: transactionBeneficiary?.proxyType && transactionBeneficiary?.proxyValue
                    ? `${transactionBeneficiary?.proxyType} ${transactionBeneficiary?.proxyValue}`
                    : '-',
                }, {
                  label: lang.get('common.form.country.label'),
                  children: countryFormatter.format(transaction.country, '-'),
                  span: 2,
                }, {
                  label: lang.get('transaction.modal.accountId.label'),
                  children: transaction.virtualAccount?.id ?? '-',
                }, {
                  label: lang.get('transaction.modal.accountNumber.label'),
                  children: transaction.virtualAccount?.externalId ?? '-',
                }]}
              />

              <Form.Divider />

              <Descriptions
                items={[{
                  label: lang.get('transaction.modal.bankId.label'),
                  children: transactionBeneficiary?.bankId ?? '-',
                }, {
                  label: lang.get('transaction.modal.bankName.label'),
                  children: transactionBeneficiary?.bankName ?? '-',
                }, {
                  label: lang.get('transaction.modal.bankCode.label'),
                  children: transactionBeneficiary?.bankCode ?? '-',
                }, {
                  label: lang.get('transaction.modal.bankCountry.label'),
                  children: countryFormatter.format(transactionBeneficiary?.bankCountryCode, '-'),
                }, {
                  label: lang.get('transaction.modal.bankBranch.label'),
                  children: transactionBeneficiary?.branchCode ?? '-',
                }, {
                  label: lang.get('transaction.modal.bankSwift.label'),
                  children: transactionBeneficiary?.swiftBic ?? '-',
                }, {
                  label: lang.get('transaction.modal.bankAccount.label'),
                  children: transactionBeneficiary?.accountNo ?? '-',
                }]}
              />

            </>
          ),
        }, {
          key: TransactionModalSection.PAYMENT,
          label: lang.get('transaction.modal.sections.payment'),
          children: (
            <>

              <Descriptions
                items={[{
                  label: lang.get('transaction.modal.provider.label'),
                  children: transaction.provider?.name ?? '-',
                  span: 2,
                }, {
                  label: lang.get('transaction.modal.type.label'),
                  children: transactionFormatter.formatType(transaction.transactionType),
                }, {
                  label: lang.get('transaction.modal.payoutMethod.label'),
                  children: transaction.payoutMethod
                    ? transactionFormatter.formatPayoutMethod(transaction.payoutMethod)
                    : '-',
                }, {
                  label: lang.get('common.form.creationDate.label'),
                  children: formatter.formatDateTime(transaction.createdAt),
                }, {
                  label: lang.get('common.form.updateDate.label'),
                  children: formatter.formatDateTime(transaction.updatedAt ?? transaction.createdAt),
                }, {
                  label: lang.get('common.form.status.label'),
                  children: <TransactionStatus status={transaction.status} />,
                }]}
              />

              <Form.Divider />

              <Descriptions
                items={[{
                  label: lang.get('transaction.modal.originAmount.label'),
                  children: formatter.formatCurrency(transaction.originAmount, transaction.originCurrency),
                }, {
                  label: lang.get('transaction.modal.destinationAmount.label'),
                  children: formatter.formatCurrency(transaction.destinationAmount, transaction.destinationCurrency),
                }, {
                  label: lang.get('transaction.modal.currencyRate.label'),
                  children: formatter.formatNumber(transaction.currencyRate, config.CURRENCY_RATE_PRECISION),
                }, {
                  label: lang.get('transaction.modal.effectiveRate.label'),
                  children: formatter.formatNumber(transaction.effectiveRate || transaction.currencyRate, config.CURRENCY_RATE_PRECISION),
                }]}
              />

              <Form.Divider />

              <Descriptions
                items={[{
                  label: lang.get('transaction.modal.transactionFee.label'),
                  children: formatter.formatCurrency(transaction.transactionFee, transaction.originCurrency),
                }, {
                  label: lang.get('transaction.modal.fxMarkup.label'),
                  children: formatter.formatCurrency(transaction.fxMarkupFee, transaction.originCurrency),
                }, {
                  label: lang.get('transaction.modal.providerFee.label'),
                  children: formatter.formatCurrency(transaction.providerFee, transaction.originCurrency),
                }, {
                  label: lang.get('transaction.modal.feeDetails.label'),
                  children: transaction.feeCommission
                    ? (
                      <Link to={url.toFeeCommission(transaction.feeCommission.id)}>
                        {lang.get('common.actions.view')}
                      </Link>
                    )
                    : '-',
                }]}
              />

            </>
          ),
        }, {
          key: TransactionModalSection.META,
          label: lang.get('transaction.modal.sections.meta'),
          children: (
            <>

              <Descriptions
                items={[{
                  label: lang.get('transaction.modal.id.label'),
                  children: transaction.id,
                }, {
                  label: lang.get('transaction.modal.flowId.label'),
                  children: transaction.flowId ?? '-',
                }, {
                  label: lang.get('transaction.modal.externalId.label'),
                  children: transaction.externalId ?? '-',
                }, {
                  label: lang.get('transaction.modal.providerReferenceId.label'),
                  children: transaction.providerReferenceId ?? '-',
                }, {
                  label: lang.get('transaction.modal.clientReferenceId.label'),
                  children: transaction.clientReferenceId ?? '-',
                }]}
              />

              <Form.Divider />

              <Descriptions
                items={[{
                  label: lang.get('transaction.modal.paymentDetails.label'),
                  children: transactionMetadata?.paymentDetails ?? '-',
                  span: 2,
                }, {
                  label: lang.get('transaction.modal.additionalInfo.label'),
                  children: transactionMetadata?.additionalInfo ?? '-',
                  span: 2,
                }, {
                  label: lang.get('common.form.notes.label'),
                  children: transaction.notes ?? '-',
                  span: 2,
                }]}
              />

            </>
          ),
        }, {
          key: TransactionModalSection.REQUEST,
          label: lang.get('transaction.modal.sections.request'),
          children: (
            <Paragraph className={styles.modal__details}>
              <pre>{JSON.stringify(transactionMetadata?.request, null, 2)}</pre>
            </Paragraph>
          ),
          hidden: !transactionMetadata?.request,
        }, {
          key: TransactionModalSection.RESPONSE,
          label: lang.get('transaction.modal.sections.response'),
          children: (
            <Paragraph className={styles.modal__details}>
              <pre>{JSON.stringify(transactionMetadata?.response, null, 2)}</pre>
            </Paragraph>
          ),
          hidden: !transactionMetadata?.response,
        }, {
          key: TransactionModalSection.SECURITY,
          label: lang.get('transaction.modal.sections.security'),
          children: (
            <Descriptions
              items={[{
                label: lang.get('transaction.modal.securityDeviceId.label'),
                children: transactionSecurityInfo?.deviceId ?? '-',
              }, {
                label: lang.get('transaction.modal.securityDeviceName.label'),
                children: transactionSecurityInfo?.deviceName ?? '-',
              }, {
                label: lang.get('transaction.modal.securityOs.label'),
                children: transactionSecurityInfo?.os ?? '-',
              }, {
                label: lang.get('transaction.modal.securityPhysical.label'),
                children: typeof transactionSecurityInfo?.isPhysical === 'boolean'
                  ? transactionSecurityInfo.isPhysical ? lang.get('common.actions.yes') : lang.get('common.actions.no')
                  : '-',
              }, {
                label: lang.get('transaction.modal.securityIps.label'),
                children: transactionSecurityInfo?.ips?.join(', ') ?? '-',
              }, {
                label: lang.get('transaction.modal.securityForwardedFor.label'),
                children: transactionSecurityInfo?.xForwardedFor?.join(', ') ?? '-',
              }, {
                label: lang.get('transaction.modal.securityUserAgent.label'),
                children: transactionSecurityInfo?.userAgent ?? '-',
              }]}
            />
          ),
        }]}
      />

    </Modal>
  );
};

export default ViewTransactionModal;

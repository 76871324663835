import React, { useState } from 'react';

import { config } from 'data';
import { useLang, useModal, useQueryModal, useTable, useTableQuery } from 'hooks';
import { useCorridorFormatter, useTransactionFormatter } from 'hooks/formatters';
import { useCorridorTypeQuery, useCorridorTypesQuery } from 'hooks/queries';
import { DashboardLayout } from 'layouts';
import { Plus } from 'components/icons';
import { ActivityStatus, Can, TableView } from 'components/layout';
import { CorridorSelect } from 'components/selects';
import { Button, Form, Select } from 'components/ui';
import { Uuid } from 'types/common';
import { TableColumns } from 'types/components';
import { CorridorTypesParams } from 'types/services';

import {
  ActivityStatus as TActivityStatus,
  CorridorType,
  CorridorTypeLevel,
  TransactionType,
  UserPermission,
} from 'types/models';

import styles from './styles.module.css';

import AddModal from './AddModal';
import ViewModal from './ViewModal';

type TableParams = CorridorTypesParams & {
  status?: TActivityStatus;
};

const initialTableParams: TableParams = {
  //
};

const CorridorTypesPage = () => {
  const lang = useLang();
  const addModal = useModal();
  const table = useTable<CorridorType, TableParams>([config.CORRIDOR_TYPES_QUERY_KEY], initialTableParams);
  const corridorFormatter = useCorridorFormatter();
  const transactionFormatter = useTransactionFormatter();

  const [corridorTypeId, setCorridorTypeId] = useState<Uuid>();

  const viewModal = useQueryModal(config.CORRIDOR_TYPES_QUERY_KEY, setCorridorTypeId);

  const corridorTypeQuery = useCorridorTypeQuery(corridorTypeId);

  const corridorTypesQuery = useCorridorTypesQuery({
    page: table.page,
    corridorId: table.params.corridorId || undefined,
    level: table.params.level || undefined,
    type: table.params.type || undefined,
    active: table.params.status ? table.params.status === TActivityStatus.ACTIVE : undefined,
  });

  const handleAddClick = () => {
    addModal.show();
  };

  const handleViewClick = (corridorType: CorridorType) => () => {
    viewModal.show(corridorType.id);
  };

  useTableQuery(table, corridorTypesQuery);

  const columns: TableColumns<CorridorType> = [
    {
      key: 'level',
      title: lang.get('corridorType.list.level'),
      render: (_, corridorType) => lang.get(`corridorType.levels.${corridorType.level.toLowerCase()}`),
    }, {
      className: styles.table__type,
      key: 'transactionType',
      title: lang.get('corridorType.list.transactionType'),
      render: (_, corridorType) => transactionFormatter.formatType(corridorType.type),
    }, {
      key: 'corridor',
      title: lang.get('corridorType.list.corridor'),
      render: (_, corridorType) => corridorFormatter.format(corridorType.currencyCorridor.country, corridorType.currencyCorridor.currency),
    }, {
      key: 'status',
      title: lang.get('corridorType.list.status'),
      render: (_, corridorType) => <ActivityStatus active={corridorType.active} />,
    },
  ];

  return (
    <DashboardLayout title={lang.get('corridorType.list.title')}>
      <TableView
        title={lang.get('corridorType.list.title')}
        actions={(
          <Can permissions={[UserPermission.CURRENCY_CORRIDOR_TRANSACTION_TYPES_UPDATE]}>
            <Button icon={<Plus />} onClick={handleAddClick}>
              {lang.get('corridorType.list.add')}
            </Button>
          </Can>
        )}
      >

        <TableView.Filters<TableParams>
          initialValues={initialTableParams}
          values={table.params}
          onSubmit={table.setParams}
        >
          <Form.Item name="corridorId" label={lang.get('common.filters.corridor')}>
            <CorridorSelect placeholder={lang.get('common.actions.all')} allowClear />
          </Form.Item>
          <Form.Item name="level" label={lang.get('corridorType.filters.level')}>
            <Select
              placeholder={lang.get('common.actions.all')}
              options={Object.values(CorridorTypeLevel).map((level) => ({
                value: level,
                label: lang.get(`corridorType.levels.${level.toLowerCase()}`),
              }))}
              allowClear
            />
          </Form.Item>
          <Form.Item name="type" label={lang.get('corridorType.filters.transactionType')}>
            <Select
              placeholder={lang.get('common.actions.all')}
              options={Object.values(TransactionType).map((type) => ({
                value: type,
                label: transactionFormatter.formatType(type),
              }))}
              allowClear
            />
          </Form.Item>
          <Form.Item name="status" label={lang.get('common.filters.status')}>
            <Select
              placeholder={lang.get('common.actions.all')}
              options={Object.values(TActivityStatus).map((status) => ({
                value: status,
                label: lang.get(`common.activityStatuses.${status.toLowerCase()}`),
              }))}
              allowClear
            />
          </Form.Item>
        </TableView.Filters>

        <TableView.Table<CorridorType>
          table={table}
          columns={columns}
          rowKey={(corridorType) => corridorType.id}
          loading={corridorTypesQuery.isLoading}
          clickable
          onRow={(corridorType) => ({ onClick: handleViewClick(corridorType) })}
        />

        <AddModal
          open={addModal.open}
          onClose={addModal.hide}
        />

        {corridorTypeQuery.data && (
          <ViewModal
            corridorType={corridorTypeQuery.data}
            open={!corridorTypeQuery.isLoading && viewModal.open}
            onClose={viewModal.hide}
          />
        )}

      </TableView>
    </DashboardLayout>
  );
};

export default CorridorTypesPage;

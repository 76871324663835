import React, { FC } from 'react';

import { useLang, useModal } from 'hooks';
import { useBusinessAccountsBalancesReportQuery } from 'hooks/queries';
import { CardView } from 'components/layout';
import { Button } from 'components/ui';
import { BusinessAccountType } from 'types/models';

import ListModal from './ListModal';

type BusinessAccountsBalancesViewProps = {
  type: BusinessAccountType;
};

const BusinessAccountsBalancesView: FC<BusinessAccountsBalancesViewProps> = ({ type }) => {
  const lang = useLang();
  const modal = useModal();

  const balancesQuery = useBusinessAccountsBalancesReportQuery({ clientType: type });

  return (
    <>

      <CardView
        title={lang.get('businessAccount.balances.title')}
        caption={lang.choice('businessAccount.balances.caption', balancesQuery.data?.total ?? 0)}
        actions={(
          <Button color="primary" variant="filled" onClick={modal.show}>
            {lang.get('common.actions.view')}
          </Button>
        )}
      />

      <ListModal
        type={type}
        open={modal.open}
        onClose={modal.hide}
      />

    </>
  );
};

export default BusinessAccountsBalancesView;

import React, { FC, useEffect } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

import { usePreloader } from 'hooks';
import { NotFoundPage } from 'pages';
import { Nullable } from 'types/common';

import BusinessAccount from './BusinessAccount';

type SinglePageProps<DataType> = {
  query: UseQueryResult<Nullable<DataType>>;
  returnUrl?: string;
  render: (data: DataType) => ReturnType<FC>;
};

const SinglePage = <DataType extends object>({
  query,
  returnUrl,
  render,
}: SinglePageProps<DataType>) => {
  const preloader = usePreloader();

  useEffect(() => {
    preloader.toggle(query.isLoading);
  }, [preloader, query.isLoading]);

  if (query.isError) {
    return (
      <NotFoundPage
        customError={query.error}
        returnUrl={returnUrl}
      />
    );
  }

  return query.data ? render(query.data) : null;
};

export default Object.assign(SinglePage, { BusinessAccount });

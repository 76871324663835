import React, { ComponentPropsWithoutRef, FC } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

type ContainerProps = ComponentPropsWithoutRef<'div'> & {
  fluid?: boolean;
};

const Container: FC<ContainerProps> = ({ className, fluid, ...props }) => (
  <div
    className={cx(
      styles.root,
      fluid && styles.root_fluid,
      className,
    )}
    {...props}
  />
);

export default Container;

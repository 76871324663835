import React, { useCallback, useMemo, useState } from 'react';

import { config, moment } from 'data';
import { formatter } from 'helpers';
import { reportService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useLang, useModal, useQuery, useTable, useTableQuery } from 'hooks';
import { DashboardLayout } from 'layouts';
import { TableView } from 'components/layout';
import { CurrencySelect, ProviderSelect } from 'components/selects';
import { DateRangePicker, Form, Select } from 'components/ui';
import { ExportColumns, TableColumns } from 'types/components';
import { ReportProvidersDailySettlementItem, ReportProvidersDailySettlementStatus } from 'types/models';
import { ReportProvidersDailySettlementParams } from 'types/services';

import styles from './styles.module.css';

import BreakdownModal from './BreakdownModal';
import SettlementStatus from './Status';

const EXPORT_FILE_NAME = 'report-providers-daily-settlement';

type TableParams = Omit<ReportProvidersDailySettlementParams, 'dateFrom' | 'dateTo'> & {
  dates: [string, string];
};

const initialTableParams: TableParams = {
  dates: [
    moment().subtract(7, 'day').startOf('day').toISOString(),
    moment().subtract(1, 'day').endOf('day').toISOString(),
  ],
};

const ProvidersDailySettlementPage = () => {
  const lang = useLang();
  const modal = useModal();
  const table = useTable<ReportProvidersDailySettlementItem, TableParams>([config.PROVIDERS_DAILY_SETTLEMENT_QUERY_KEY], initialTableParams);

  const [selectedItem, setSelectedItem] = useState<ReportProvidersDailySettlementItem>();

  const reportParams: ReportProvidersDailySettlementParams = {
    page: table.page,
    providerId: table.params.providerId || undefined,
    currency: table.params.currency || undefined,
    status: table.params.status || undefined,
    dateFrom: table.params.dates[0],
    dateTo: table.params.dates[1],
  };

  const reportQuery = useQuery({
    queryKey: [config.PROVIDERS_DAILY_SETTLEMENT_QUERY_KEY, reportParams],
    queryFn: () => reportService.getProvidersDailySettlement(reportParams),
  });

  const handleViewClick = (item: ReportProvidersDailySettlementItem) => () => {
    setSelectedItem(item);

    modal.show();
  };

  useTableQuery(table, reportQuery);

  const formatItemDate = (item: ReportProvidersDailySettlementItem) => formatter.formatDate(item.date);

  const formatItemProvider = (item: ReportProvidersDailySettlementItem) => item.provider.name;

  const formatItemTransactions = (item: ReportProvidersDailySettlementItem) => item.totalTransactions;

  const formatItemCurrency = (item: ReportProvidersDailySettlementItem) => item.currency;

  const formatItemTransactionAmount = (item: ReportProvidersDailySettlementItem) => formatter.formatNumber(item.totalAmount);

  const formatItemTransactionFees = (item: ReportProvidersDailySettlementItem) => formatter.formatNumber(item.grossTransactionFees);

  const formatItemNetClientPayment = (item: ReportProvidersDailySettlementItem) => formatter.formatNumber(item.netClientPayment);

  const formatItemProviderFees = (item: ReportProvidersDailySettlementItem) => formatter.formatNumber(item.totalProviderFees);

  const formatItemFeeIncome = (item: ReportProvidersDailySettlementItem) => formatter.formatNumber(item.netIncome);

  const formatItemStatus = useCallback((item: ReportProvidersDailySettlementItem) => lang.get(`provider.dailySettlement.statuses.${item.status.toLowerCase()}`), [lang]);

  const exportColumns: ExportColumns<ReportProvidersDailySettlementItem> = useMemo(() => [
    {
      title: lang.get('provider.dailySettlement.date'),
      render: (item) => formatItemDate(item),
    }, {
      title: lang.get('provider.dailySettlement.provider'),
      render: (item) => formatItemProvider(item),
    }, {
      title: lang.get('provider.dailySettlement.transactions'),
      render: (item) => formatItemTransactions(item),
    }, {
      title: lang.get('provider.dailySettlement.currency'),
      render: (item) => formatItemCurrency(item),
    }, {
      title: lang.get('provider.dailySettlement.transactionAmount'),
      render: (item) => formatItemTransactionAmount(item),
    }, {
      title: lang.get('provider.dailySettlement.transactionFees'),
      render: (item) => formatItemTransactionFees(item),
    }, {
      title: lang.get('provider.dailySettlement.netClientPayment'),
      render: (item) => formatItemNetClientPayment(item),
    }, {
      title: lang.get('provider.dailySettlement.providerFees'),
      render: (item) => formatItemProviderFees(item),
    }, {
      title: lang.get('provider.dailySettlement.feeIncome'),
      render: (item) => formatItemFeeIncome(item),
    }, {
      title: lang.get('provider.dailySettlement.status'),
      render: (item) => formatItemStatus(item),
    },
  ], [lang, formatItemStatus]);

  const tableColumns: TableColumns<ReportProvidersDailySettlementItem> = [
    {
      className: styles.table__date,
      key: 'date',
      title: lang.get('provider.dailySettlement.date'),
      render: (_, item) => formatItemDate(item),
    }, {
      key: 'provider',
      title: lang.get('provider.dailySettlement.provider'),
      render: (_, item) => formatItemProvider(item),
    }, {
      key: 'transactions',
      title: lang.get('provider.dailySettlement.transactions'),
      render: (_, item) => formatItemTransactions(item),
    }, {
      key: 'currency',
      title: lang.get('provider.dailySettlement.currency'),
      render: (_, item) => formatItemCurrency(item),
    }, {
      className: styles.table__balance,
      key: 'transactionAmount',
      title: lang.get('provider.dailySettlement.transactionAmount'),
      render: (_, item) => formatItemTransactionAmount(item),
    }, {
      className: styles.table__balance,
      key: 'transactionFees',
      title: lang.get('provider.dailySettlement.transactionFees'),
      render: (_, item) => formatItemTransactionFees(item),
    }, {
      className: styles.table__amount,
      key: 'netClientPayment',
      title: lang.get('provider.dailySettlement.netClientPayment'),
      render: (_, item) => formatItemNetClientPayment(item),
    }, {
      className: styles.table__balance,
      key: 'providerFees',
      title: lang.get('provider.dailySettlement.providerFees'),
      render: (_, item) => formatItemProviderFees(item),
    }, {
      className: styles.table__balance,
      key: 'feeIncome',
      title: lang.get('provider.dailySettlement.feeIncome'),
      render: (_, item) => formatItemFeeIncome(item),
    }, {
      key: 'status',
      title: lang.get('provider.dailySettlement.status'),
      render: (_, item) => <SettlementStatus status={item.status} />,
    },
  ];

  return (
    <DashboardLayout title={lang.get('provider.dailySettlement.title')}>
      <TableView
        title={lang.get('provider.dailySettlement.title')}
        actions={(
          <TableView.ExportButton<ReportProvidersDailySettlementItem>
            table={table}
            fileName={[
              EXPORT_FILE_NAME,
              moment(table.params.dates[0]).format(config.DATE_RAW_FORMAT),
              moment(table.params.dates[1]).format(config.DATE_RAW_FORMAT),
            ].join('-')}
            columns={exportColumns}
            fetchData={() => fetchPaginatedResponseFully(reportService.getProvidersDailySettlement, reportParams)}
          />
        )}
      >

        <TableView.Filters<TableParams>
          initialValues={initialTableParams}
          values={table.params}
          onSubmit={table.setParams}
        >
          <Form.Item name="providerId" label={lang.get('common.filters.provider')}>
            <ProviderSelect placeholder={lang.get('common.actions.all')} allowClear />
          </Form.Item>
          <Form.Item name="currency" label={lang.get('common.filters.currency')}>
            <CurrencySelect placeholder={lang.get('common.actions.all')} allowClear />
          </Form.Item>
          <Form.Item name="status" label={lang.get('common.filters.status')}>
            <Select
              placeholder={lang.get('common.actions.all')}
              options={Object.values(ReportProvidersDailySettlementStatus).map((status) => ({
                value: status,
                label: lang.get(`provider.dailySettlement.statuses.${status.toLowerCase()}`),
              }))}
              allowClear
            />
          </Form.Item>
          <Form.Item name="dates" label={lang.get('common.filters.date')}>
            <DateRangePicker maxDate={moment().subtract(1, 'day').endOf('day')} allowClear={false} />
          </Form.Item>
        </TableView.Filters>

        <TableView.Table<ReportProvidersDailySettlementItem>
          table={table}
          columns={tableColumns}
          rowKey={(item) => item.provider.id + item.date + item.currency}
          loading={reportQuery.isLoading}
          clickable
          onRow={(item) => ({ onClick: handleViewClick(item) })}
        />

        {selectedItem && (
          <BreakdownModal
            item={selectedItem}
            open={modal.open}
            onClose={modal.hide}
          />
        )}

      </TableView>
    </DashboardLayout>
  );
};

export default ProvidersDailySettlementPage;

import React, { FC } from 'react';

import { useLang } from 'hooks';
import { useAllFeeGroupsQuery } from 'hooks/queries';
import { Select } from 'components/ui';
import { SelectProps } from 'types/components';

type FeeGroupSelectProps = Omit<SelectProps, 'options' | 'loading'>;

const FeeGroupSelect: FC<FeeGroupSelectProps> = (props) => {
  const lang = useLang();

  const feeGroupsQuery = useAllFeeGroupsQuery();

  return (
    <Select
      placeholder={lang.get('common.form.feeGroup.placeholder')}
      options={feeGroupsQuery.data?.map((group) => ({
        value: group.id,
        label: group.name,
      }))}
      loading={feeGroupsQuery.isLoading}
      {...props}
    />
  );
};

export default FeeGroupSelect;

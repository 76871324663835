import React, { FC } from 'react';

import { useLang } from 'hooks';
import { useCorridorFormatter } from 'hooks/formatters';
import { useAllCorridorsQuery } from 'hooks/queries';
import { Select } from 'components/ui';
import { SelectProps } from 'types/components';

type CorridorSelectProps = Omit<SelectProps, 'options' | 'loading'>;

const CorridorSelect: FC<CorridorSelectProps> = ({
  showSearch = true,
  ...props
}) => {
  const lang = useLang();
  const corridorFormatter = useCorridorFormatter();

  const corridorsQuery = useAllCorridorsQuery();

  return (
    <Select
      placeholder={lang.get('common.form.corridor.placeholder')}
      options={corridorsQuery.data?.map((corridor) => ({
        value: corridor.id,
        label: corridorFormatter.format(corridor.country, corridor.currency),
      }))}
      loading={corridorsQuery.isLoading}
      showSearch={showSearch}
      {...props}
    />
  );
};

export default CorridorSelect;

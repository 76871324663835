import React, { useState } from 'react';

import { config } from 'data';
import { useLang, useQueryModal, useTable, useTableQuery } from 'hooks';
import { useUserRoleQuery, useUserRolesQuery } from 'hooks/queries';
import { DashboardLayout } from 'layouts';
import { Plus } from 'components/icons';
import { Can, TableView } from 'components/layout';
import { Button, Table } from 'components/ui';
import { Uuid } from 'types/common';
import { TableColumns } from 'types/components';
import { UserPermission, UserRole } from 'types/models';

import styles from './styles.module.css';

import Modal from './Modal';

const UserRolesPage = () => {
  const lang = useLang();
  const table = useTable<UserRole>([config.USER_ROLES_QUERY_KEY]);

  const [roleId, setRoleId] = useState<Uuid>();

  const modal = useQueryModal(config.USER_ROLES_QUERY_KEY, setRoleId);

  const rolesQuery = useUserRolesQuery({ page: table.page });
  const roleQuery = useUserRoleQuery(roleId);

  const handleCreateClick = () => {
    modal.show();
  };

  const handleViewClick = (role: UserRole) => () => {
    modal.show(role.id);
  };

  useTableQuery(table, rolesQuery);

  const columns: TableColumns<UserRole> = [
    {
      className: styles.table__name,
      key: 'name',
      title: lang.get('role.list.name'),
      render: (_, role) => <Table.Truncate>{role.name}</Table.Truncate>,
    },
  ];

  return (
    <DashboardLayout title={lang.get('role.list.title')}>
      <TableView
        title={lang.get('role.list.title')}
        actions={(
          <Can permissions={[UserPermission.USER_ROLES_UPDATE]}>
            <Button icon={<Plus />} onClick={handleCreateClick}>
              {lang.get('role.list.create')}
            </Button>
          </Can>
        )}
      >

        <TableView.Table<UserRole>
          table={table}
          columns={columns}
          rowKey={(role) => role.id}
          loading={rolesQuery.isLoading}
          clickable
          onRow={(role) => ({ onClick: handleViewClick(role) })}
        />

        <Modal
          role={roleQuery.data}
          open={!roleQuery.isLoading && modal.open}
          onClose={modal.hide}
        />

      </TableView>
    </DashboardLayout>
  );
};

export default UserRolesPage;

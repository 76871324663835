import React, { FC, useMemo } from 'react';

import { config } from 'data';
import { formatter } from 'helpers';
import { reportService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useLang, useTable, useTableQuery } from 'hooks';
import { useBusinessAccountsBalancesReportQuery } from 'hooks/queries';
import { TableView } from 'components/layout';
import { Modal } from 'components/ui';
import { ExportColumns, ModalBaseProps, TableColumns } from 'types/components';
import { BusinessAccountType, ReportBusinessAccountsBalancesItem } from 'types/models';
import { ReportBusinessAccountsBalancesParams } from 'types/services';

import styles from './styles.module.css';

const EXPORT_FILE_NAME = 'business-accounts-balances';

type BusinessAccountsBalancesModalProps = ModalBaseProps & {
  type: BusinessAccountType;
};

const BusinessAccountsBalancesModal: FC<BusinessAccountsBalancesModalProps> = ({
  type,
  open,
  onClose,
}) => {
  const lang = useLang();
  const table = useTable<ReportBusinessAccountsBalancesItem>([config.BUSINESS_ACCOUNTS_BALANCES_QUERY_KEY, type]);

  const balancesParams: ReportBusinessAccountsBalancesParams = {
    page: table.page,
    clientType: type,
  };

  const balancesQuery = useBusinessAccountsBalancesReportQuery(balancesParams);

  useTableQuery(table, balancesQuery);

  const formatItemCurrency = (item: ReportBusinessAccountsBalancesItem) => item.currency;

  const formatItemAvailable = (item: ReportBusinessAccountsBalancesItem) => formatter.formatNumber(item.available);

  const formatItemOutgoingPending = (item: ReportBusinessAccountsBalancesItem) => formatter.formatNumber(item.outgoingPending);

  const formatItemIncomingPending = (item: ReportBusinessAccountsBalancesItem) => formatter.formatNumber(item.incomingPending);

  const formatItemTotal = (item: ReportBusinessAccountsBalancesItem) => formatter.formatNumber(item.total);

  const exportColumns: ExportColumns<ReportBusinessAccountsBalancesItem> = useMemo(() => [
    {
      title: lang.get('businessAccount.balances.currency'),
      render: (item) => formatItemCurrency(item),
    }, {
      title: lang.get('businessAccount.balances.available'),
      render: (item) => formatItemAvailable(item),
    }, {
      title: lang.get('businessAccount.balances.outgoingPending'),
      render: (item) => formatItemOutgoingPending(item),
    }, {
      title: lang.get('businessAccount.balances.incomingPending'),
      render: (item) => formatItemIncomingPending(item),
    }, {
      title: lang.get('businessAccount.balances.total'),
      render: (item) => formatItemTotal(item),
    },
  ], [lang]);

  const tableColumns: TableColumns<ReportBusinessAccountsBalancesItem> = [
    {
      key: 'currency',
      title: lang.get('businessAccount.balances.currency'),
      render: (_, item) => formatItemCurrency(item),
    }, {
      className: styles.table__amount,
      key: 'available',
      title: lang.get('businessAccount.balances.available'),
      render: (_, item) => formatItemAvailable(item),
    }, {
      className: styles.table__balance,
      key: 'outgoingPending',
      title: lang.get('businessAccount.balances.outgoingPending'),
      render: (_, item) => formatItemOutgoingPending(item),
    }, {
      className: styles.table__balance,
      key: 'incomingPending',
      title: lang.get('businessAccount.balances.incomingPending'),
      render: (_, item) => formatItemIncomingPending(item),
    }, {
      className: styles.table__balance,
      key: 'total',
      title: lang.get('businessAccount.balances.total'),
      render: (_, item) => formatItemTotal(item),
    },
  ];

  return (
    <Modal
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      width="large"
      open={open}
      onCancel={onClose}
    >
      <TableView
        title={lang.get('businessAccount.balances.title')}
        caption={lang.choice('businessAccount.balances.caption', balancesQuery.data?.total ?? 0)}
        actions={(
          <TableView.ExportButton<ReportBusinessAccountsBalancesItem>
            table={table}
            type="default"
            fileName={EXPORT_FILE_NAME}
            columns={exportColumns}
            fetchData={() => fetchPaginatedResponseFully(reportService.getBusinessAccountsBalances, balancesParams)}
          />
        )}
      >
        <TableView.Table<ReportBusinessAccountsBalancesItem>
          table={table}
          columns={tableColumns}
          rowKey={(item) => item.currency}
          loading={balancesQuery.isLoading}
        />
      </TableView>
    </Modal>
  );
};

export default BusinessAccountsBalancesModal;

import { useCallback, useMemo } from 'react';

import { CountryCode, CurrencyCode } from 'types/models';

import useCountryFormatter from './country';
import useCurrencyFormatter from './currency';

const useCorridorFormatter = () => {
  const countryFormatter = useCountryFormatter();
  const currencyFormatter = useCurrencyFormatter();

  const format = useCallback((country: CountryCode, currency: CurrencyCode) => {
    return `${countryFormatter.format(country)} - ${currencyFormatter.format(currency)}`;
  }, [countryFormatter, currencyFormatter]);

  return useMemo(() => ({ format }), [format]);
};

export default useCorridorFormatter;

import React, { FC } from 'react';

import { useLang } from 'hooks';
import { Tag } from 'components/ui';
import { Override } from 'types/common';
import { TagProps } from 'types/components';

type VerificationStatusProps = Override<Omit<TagProps, 'color' | 'children'>, {
  verified: boolean;
}>;

const VerificationStatus: FC<VerificationStatusProps> = ({ verified, ...props }) => {
  const lang = useLang();

  return (
    <Tag color={verified ? 'success' : 'error'} {...props}>
      {lang.get(verified ? 'common.verificationStatuses.verified' : 'common.verificationStatuses.unverified')}
    </Tag>
  );
};

export default VerificationStatus;

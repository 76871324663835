import React, { FC } from 'react';

import { config } from 'data';
import { formatter } from 'helpers';
import { useLang } from 'hooks';
import { useCorridorFormatter, useTransactionFormatter } from 'hooks/formatters';
import { useAllFeeCommissionsQuery } from 'hooks/queries';
import { ActivityStatus } from 'components/layout';
import { Flex, Select } from 'components/ui';
import { SelectProps } from 'types/components';
import { FeeCommissionsParams } from 'types/services';

import styles from './styles.module.css';

type FeeCommissionSelectProps = Omit<SelectProps, 'options' | 'loading'> & {
  params?: FeeCommissionsParams;
};

const FeeCommissionSelect: FC<FeeCommissionSelectProps> = ({
  params,
  showSearch = true,
  ...props
}) => {
  const lang = useLang();
  const corridorFormatter = useCorridorFormatter();
  const transactionFormatter = useTransactionFormatter();

  const feeCommissionsQuery = useAllFeeCommissionsQuery(params);

  return (
    <Select
      placeholder={lang.get('common.form.feeCommission.placeholder')}
      options={feeCommissionsQuery.data?.map((feeCommission) => {
        const fixedFee = feeCommission.fixedTransactionFee && formatter.formatCurrency(feeCommission.fixedTransactionFee, feeCommission.transactionFeeCurrency);
        const percentFee = feeCommission.percentTransactionFee && formatter.formatPercent(feeCommission.percentTransactionFee, config.FEE_COMMISSION_PERCENT_PRECISION);

        return {
          value: feeCommission.id,
          label: (
            <Flex vertical>
              <Flex gap="small" align="center" wrap="wrap">
                <span>{feeCommission.name}</span>
                <ActivityStatus active={feeCommission.active} hidden={feeCommission.active} />
              </Flex>
              <Flex className={styles.info} gap="small" align="center" wrap="wrap">
                <span className={styles.info__item}>
                  {transactionFormatter.formatType(feeCommission.corridor.type)}
                </span>
                <span className={styles.info__separator} />
                <span className={styles.info__item}>
                  {corridorFormatter.format(feeCommission.corridor.currencyCorridor.country, feeCommission.corridor.currencyCorridor.currency)}
                </span>
                <span className={styles.info__separator} />
                <span className={styles.info__item}>
                  {[fixedFee, percentFee].filter(Boolean).join(' + ') || '-'}
                </span>
              </Flex>
            </Flex>
          ),
        };
      })}
      loading={feeCommissionsQuery.isLoading}
      showSearch={showSearch}
      {...props}
    />
  );
};

export default FeeCommissionSelect;

import { config } from 'data';
import { currencyService } from 'services';
import { useQuery } from 'hooks';

const useAllCurrenciesQuery = () => useQuery({
  queryKey: [config.CURRENCIES_QUERY_KEY, 'all'],
  queryFn: () => currencyService.getCurrencies(),
  refetchOnMount: false,
  refetchOnWindowFocus: false,
});

export default useAllCurrenciesQuery;

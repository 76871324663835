import React, { FC } from 'react';
import { Form } from 'antd';
import { ErrorListProps } from 'antd/es/form';
import cx from 'classnames';

import styles from './styles.module.css';

const ErrorList: FC<ErrorListProps> = ({ className, ...props }) => (
  <Form.ErrorList
    className={cx(styles.errorList, className)}
    {...props}
  />
);

export default ErrorList;

import React, { FC } from 'react';

import { useLang } from 'hooks';
import { useAllBusinessAccountRolesQuery } from 'hooks/queries';
import { Flex, Select, Text } from 'components/ui';
import { SelectProps } from 'types/components';

import styles from './styles.module.css';

type RoleSelectProps = Omit<SelectProps, 'options' | 'loading'>;

const RoleSelect: FC<RoleSelectProps> = (props) => {
  const lang = useLang();

  const rolesQuery = useAllBusinessAccountRolesQuery();

  return (
    <Select
      placeholder={lang.get('businessAccount.member.modal.roles.placeholder')}
      options={rolesQuery.data?.map((role) => ({
        value: role.id,
        label: (
          <Flex className={styles.role} vertical>
            <Text className={styles.role__name}>{role.name}</Text>
            <Text className={styles.role__caption} type="secondary">{role.description}</Text>
          </Flex>
        ),
      }))}
      loading={rolesQuery.isLoading}
      {...props}
    />
  );
};

export default RoleSelect;

import React, { FC, useEffect } from 'react';
import { Link } from 'react-router';

import { config, moment, yup } from 'data';
import { url } from 'helpers';
import { useForm, useFormWatch, useLang } from 'hooks';
import { useBusinessApplicationKybDetailsQuery } from 'hooks/queries';
import { CountrySelect, FeeCommissionSelect } from 'components/selects';
import { Checkbox, DatePicker, Form, Input, Modal } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { BusinessApplicationVerifyParams, FeeCommissionsParams } from 'types/services';

import {
  BusinessApplication,
  BusinessApplicationAddress,
  BusinessApplicationFinancialSettings,
  FeeCommissionLevel,
  TransactionType,
} from 'types/models';

import styles from './styles.module.css';

type FormFinancialSettings = BusinessApplicationFinancialSettings & {
  defaultFallBelowFee: boolean;
  defaultServiceFee: boolean;
  defaultVaOpenFee: boolean;
};

type FormValues = BusinessApplicationVerifyParams & {
  financialSettings: FormFinancialSettings;
};

const initialAddress: BusinessApplicationAddress = {
  countryCode: '',
  state: '',
  city: '',
  street: '',
  zip: '',
};

const initialFinancialSettings: FormFinancialSettings = {
  lowBalanceLevel: 0,
  fallBelowFeeId: null,
  serviceFeeId: null,
  vaOpenFeeId: null,
  defaultFallBelowFee: false,
  defaultServiceFee: false,
  defaultVaOpenFee: false,
};

const initialValues: FormValues = {
  externalCode: '',
  registryDate: '',
  isRegistryAddressSameAsPrincipal: true,
  registryAddress: initialAddress,
  principalAddress: initialAddress,
  financialSettings: initialFinancialSettings,
};

const addressValidationSchema = yup.object({
  countryCode: yup.string().required().country(),
  state: yup.string().required().min(config.STATE_MIN_LENGTH).max(config.STATE_MAX_LENGTH),
  city: yup.string().required().min(config.CITY_MIN_LENGTH).max(config.CITY_MAX_LENGTH),
  street: yup.string().required().min(config.STREET_MIN_LENGTH).max(config.STREET_MAX_LENGTH),
  zip: yup.string().required().min(config.ZIP_MIN_LENGTH).max(config.ZIP_MAX_LENGTH),
});

const financialSettingsValidationSchema = yup.object({
  lowBalanceLevel: yup.number().required().decimal().min(0).transform((value) => value || 0),
  fallBelowFeeId: yup
    .string()
    .required()
    .uuid()
    .default(initialFinancialSettings.fallBelowFeeId)
    .when('defaultFallBelowFee', ([useDefault], schema) => useDefault ? schema.notRequired() : schema),
  serviceFeeId: yup
    .string()
    .required()
    .uuid()
    .default(initialFinancialSettings.serviceFeeId)
    .when('defaultServiceFee', ([useDefault], schema) => useDefault ? schema.notRequired() : schema),
  vaOpenFeeId: yup
    .string()
    .required()
    .uuid()
    .default(initialFinancialSettings.vaOpenFeeId)
    .when('defaultVaOpenFee', ([useDefault], schema) => useDefault ? schema.notRequired() : schema),
  defaultFallBelowFee: yup.boolean().required().default(initialFinancialSettings.defaultFallBelowFee),
  defaultServiceFee: yup.boolean().required().default(initialFinancialSettings.defaultServiceFee),
  defaultVaOpenFee: yup.boolean().required().default(initialFinancialSettings.defaultVaOpenFee),
});

const validationSchema = yup.object({
  externalCode: yup.string().required().max(config.STRING_MAX_LENGTH),
  registryDate: yup.string().required(),
  isRegistryAddressSameAsPrincipal: yup.boolean().required(),
  financialSettings: financialSettingsValidationSchema,
  registryAddress: addressValidationSchema,
  principalAddress: addressValidationSchema,
});

const fallBelowFeeParams: FeeCommissionsParams = {
  level: [FeeCommissionLevel.CLIENT],
  transactionType: [TransactionType.FALL_BELOW_FEE],
};

const serviceFeeParams: FeeCommissionsParams = {
  level: [FeeCommissionLevel.CLIENT],
  transactionType: [TransactionType.SERVICE_FEE],
};

const vaOpenFeeParams: FeeCommissionsParams = {
  level: [FeeCommissionLevel.CLIENT],
  transactionType: [TransactionType.VA_OPEN],
};

type VerifyBusinessApplicationModalProps = ModalBaseProps & {
  businessApplication: BusinessApplication;
  loading?: boolean;
  onSubmit: (values: FormValues) => Promise<void>;
};

const VerifyBusinessApplicationModal: FC<VerifyBusinessApplicationModalProps> = ({
  businessApplication,
  open,
  loading,
  onClose,
  onSubmit,
}) => {
  const form = useForm<FormValues>();
  const lang = useLang();

  const currentIsSameAddress = useFormWatch('isRegistryAddressSameAsPrincipal', form);
  const currentRegistryAddress = useFormWatch('registryAddress', form);
  const currentDefaultFallBelowFee = useFormWatch(['financialSettings', 'defaultFallBelowFee'], form);
  const currentDefaultServiceFee = useFormWatch(['financialSettings', 'defaultServiceFee'], form);
  const currentDefaultVaOpenFee = useFormWatch(['financialSettings', 'defaultVaOpenFee'], form);

  const kybDetailsQuery = useBusinessApplicationKybDetailsQuery(businessApplication.kybDetails?.id);

  const handleSubmit = async (values: FormValues) => {
    await onSubmit(values);

    onClose();
  };

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        ...initialValues,
        ...businessApplication,
        externalCode: businessApplication.kybDetails?.externalCode ?? initialValues.externalCode,
        financialSettings: {
          ...initialValues.financialSettings,
          ...businessApplication.financialSettings,
        },
        registryAddress: {
          ...kybDetailsQuery.data,
          ...businessApplication.registryAddress,
        },
        principalAddress: {
          ...kybDetailsQuery.data,
          ...businessApplication.principalAddress,
        },
      } as FormValues);
    }
  }, [businessApplication, open, form, kybDetailsQuery.data]);

  useEffect(() => {
    if (form.isFieldTouched('isRegistryAddressSameAsPrincipal')) {
      form.resetFields([
        ['principalAddress', 'countryCode'],
        ['principalAddress', 'state'],
        ['principalAddress', 'city'],
        ['principalAddress', 'zip'],
        ['principalAddress', 'street'],
      ]);
    }

    if (currentIsSameAddress) {
      form.setFieldsValue({ principalAddress: currentRegistryAddress });
    }
  }, [form, currentIsSameAddress, currentRegistryAddress]);

  useEffect(() => {
    if (form.isFieldTouched(['financialSettings', 'defaultFallBelowFee'])) {
      form.resetFields([['financialSettings', 'fallBelowFeeId']]);
    }
  }, [form, currentDefaultFallBelowFee]);

  useEffect(() => {
    if (form.isFieldTouched(['financialSettings', 'defaultServiceFee'])) {
      form.resetFields([['financialSettings', 'serviceFeeId']]);
    }
  }, [form, currentDefaultServiceFee]);

  useEffect(() => {
    if (form.isFieldTouched(['financialSettings', 'defaultVaOpenFee'])) {
      form.resetFields([['financialSettings', 'vaOpenFeeId']]);
    }
  }, [form, currentDefaultVaOpenFee]);

  return (
    <Modal
      title={lang.get('businessApplication.verifyModal.title', { name: businessApplication.name })}
      caption={lang.get('businessApplication.verifyModal.caption')}
      okText={lang.get('common.actions.verify')}
      width="small"
      open={open}
      confirmLoading={loading}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        disabled={kybDetailsQuery.isLoading}
        onFinish={handleSubmit}
      >

        <Form.Columns>
          <Form.Item name="externalCode" label={lang.get('businessApplication.verifyModal.registryNumber.label')}>
            <Input placeholder={lang.get('businessApplication.verifyModal.registryNumber.placeholder')} />
          </Form.Item>
          <Form.Item name="registryDate" label={lang.get('businessApplication.verifyModal.registryDate.label')}>
            <DatePicker maxDate={moment().endOf('day')} allowClear={false} />
          </Form.Item>
        </Form.Columns>

        <Form.Divider />

        <Form.Title>{lang.get('businessApplication.verifyModal.registryAddress')}</Form.Title>
        <Form.Columns>
          <Form.Item name={['registryAddress', 'countryCode']} label={lang.get('common.form.country.label')}>
            <CountrySelect />
          </Form.Item>
          <Form.Item name={['registryAddress', 'state']} label={lang.get('common.form.state.label')}>
            <Input placeholder={lang.get('common.form.state.placeholder')} />
          </Form.Item>
        </Form.Columns>
        <Form.Columns>
          <Form.Item name={['registryAddress', 'city']} label={lang.get('common.form.city.label')}>
            <Input placeholder={lang.get('common.form.city.placeholder')} />
          </Form.Item>
          <Form.Item name={['registryAddress', 'zip']} label={lang.get('common.form.zip.label')}>
            <Input placeholder={lang.get('common.form.zip.placeholder')} />
          </Form.Item>
        </Form.Columns>
        <Form.Item name={['registryAddress', 'street']} label={lang.get('common.form.street.label')}>
          <Input placeholder={lang.get('common.form.street.placeholder')} />
        </Form.Item>
        <Form.CheckboxItem name="isRegistryAddressSameAsPrincipal">
          <Checkbox>{lang.get('businessApplication.verifyModal.sameAddress')}</Checkbox>
        </Form.CheckboxItem>

        <Form.Item hidden={currentIsSameAddress} noStyle>
          <Form.Divider />
          <Form.Title>{lang.get('businessApplication.verifyModal.principalAddress')}</Form.Title>
          <Form.Columns>
            <Form.Item name={['principalAddress', 'countryCode']} label={lang.get('common.form.country.label')}>
              <CountrySelect />
            </Form.Item>
            <Form.Item name={['principalAddress', 'state']} label={lang.get('common.form.state.label')}>
              <Input placeholder={lang.get('common.form.state.placeholder')} />
            </Form.Item>
          </Form.Columns>
          <Form.Columns>
            <Form.Item name={['principalAddress', 'city']} label={lang.get('common.form.city.label')}>
              <Input placeholder={lang.get('common.form.city.placeholder')} />
            </Form.Item>
            <Form.Item name={['principalAddress', 'zip']} label={lang.get('common.form.zip.label')}>
              <Input placeholder={lang.get('common.form.zip.placeholder')} />
            </Form.Item>
          </Form.Columns>
          <Form.Item name={['principalAddress', 'street']} label={lang.get('common.form.street.label')}>
            <Input placeholder={lang.get('common.form.street.placeholder')} />
          </Form.Item>
        </Form.Item>

        <Form.Divider />

        <Form.Title>{lang.get('businessApplication.verifyModal.financialSettings')}</Form.Title>
        <Form.Item label={lang.get('businessApplication.verifyModal.vaOpenFee.label')}>
          <Form.Item
            className={styles.modal__fee__input}
            name={['financialSettings', 'vaOpenFeeId']}
            extra={(
              <Link to={url.toFeeManagement(undefined, url.prepareFilterParams(vaOpenFeeParams))}>
                {lang.get('businessApplication.verifyModal.manageFee')}
              </Link>
            )}
            hidden={currentDefaultVaOpenFee}
          >
            <FeeCommissionSelect params={vaOpenFeeParams} />
          </Form.Item>
          <Form.CheckboxItem
            className={styles.modal__fee__checkbox}
            name={['financialSettings', 'defaultVaOpenFee']}
          >
            <Checkbox>{lang.get('businessApplication.verifyModal.useDefault')}</Checkbox>
          </Form.CheckboxItem>
        </Form.Item>
        <Form.Item label={lang.get('businessApplication.verifyModal.serviceFee.label')}>
          <Form.Item
            className={styles.modal__fee__input}
            name={['financialSettings', 'serviceFeeId']}
            extra={(
              <Link to={url.toFeeManagement(undefined, url.prepareFilterParams(serviceFeeParams))}>
                {lang.get('businessApplication.verifyModal.manageFee')}
              </Link>
            )}
            hidden={currentDefaultServiceFee}
          >
            <FeeCommissionSelect params={serviceFeeParams} />
          </Form.Item>
          <Form.CheckboxItem
            className={styles.modal__fee__checkbox}
            name={['financialSettings', 'defaultServiceFee']}
          >
            <Checkbox>{lang.get('businessApplication.verifyModal.useDefault')}</Checkbox>
          </Form.CheckboxItem>
        </Form.Item>
        <Form.Item label={lang.get('businessApplication.verifyModal.fallBelowFee.label')}>
          <Form.Item
            className={styles.modal__fee__input}
            name={['financialSettings', 'fallBelowFeeId']}
            extra={(
              <Link to={url.toFeeManagement(undefined, url.prepareFilterParams(fallBelowFeeParams))}>
                {lang.get('businessApplication.verifyModal.manageFee')}
              </Link>
            )}
            hidden={currentDefaultFallBelowFee}
          >
            <FeeCommissionSelect params={fallBelowFeeParams} />
          </Form.Item>
          <Form.CheckboxItem
            className={styles.modal__fee__checkbox}
            name={['financialSettings', 'defaultFallBelowFee']}
          >
            <Checkbox>{lang.get('businessApplication.verifyModal.useDefault')}</Checkbox>
          </Form.CheckboxItem>
        </Form.Item>
        <Form.Item name={['financialSettings', 'lowBalanceLevel']} label={lang.get('businessApplication.verifyModal.lowBalanceLevel.label')}>
          <Input.Number placeholder={lang.get('businessApplication.verifyModal.lowBalanceLevel.placeholder')} suffix={config.DEFAULT_CURRENCY} />
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default VerifyBusinessApplicationModal;

import React, { FC } from 'react';

import { formatter } from 'helpers';
import { useLang } from 'hooks';
import { useAllUsersQuery } from 'hooks/queries';
import { UserInfo } from 'components/layout';
import { Select } from 'components/ui';
import { SelectProps } from 'types/components';

import styles from './styles.module.css';

type UserSelectProps = Omit<SelectProps, 'options' | 'loading'>;

const UserSelect: FC<UserSelectProps> = ({
  showSearch = true,
  ...props
}) => {
  const lang = useLang();

  const usersQuery = useAllUsersQuery();

  return (
    <Select
      placeholder={lang.get('common.form.user.placeholder')}
      options={usersQuery.data?.map((user) => ({
        value: user.id,
        label: (
          <UserInfo
            className={styles.user}
            nameClassName={styles.user__name}
            emailClassName={styles.user__email}
            name={formatter.formatName(user.firstName, user.lastName)}
            email={user.email}
          />
        ),
        title: [user.firstName, user.lastName, user.email].join(' '),
      }))}
      loading={usersQuery.isLoading}
      showSearch={showSearch}
      {...props}
    />
  );
};

export default UserSelect;

import React, { FC, useState } from 'react';
import truncate from 'lodash/truncate';

import { config } from 'data';
import { useLang, useQueryModal } from 'hooks';
import { useAllBusinessAccountVirtualAccountsQuery } from 'hooks/queries';
import { BusinessAccountIcon, TableView, VirtualAccountStatus } from 'components/layout';
import { Table } from 'components/ui';
import { Uuid } from 'types/common';
import { TableColumns } from 'types/components';
import { BusinessAccount, BusinessAccountVirtualAccount } from 'types/models';

import styles from './styles.module.css';

import Modal from './Modal';

type MembersViewProps = {
  businessAccount: BusinessAccount;
};

const MembersView: FC<MembersViewProps> = ({ businessAccount }) => {
  const lang = useLang();

  const [virtualAccountId, setVirtualAccountId] = useState<Uuid>();

  const modal = useQueryModal(config.BUSINESS_ACCOUNT_VIRTUAL_ACCOUNTS_QUERY_KEY, setVirtualAccountId);

  const virtualAccountsQuery = useAllBusinessAccountVirtualAccountsQuery(businessAccount.id);

  const handleViewClick = (virtualAccount: BusinessAccountVirtualAccount) => () => {
    modal.show(virtualAccount.id);
  };

  const columns: TableColumns<BusinessAccountVirtualAccount> = [
    {
      key: 'name',
      title: lang.get('businessAccount.virtualAccount.list.name'),
      render: (_, virtualAccount) => <Table.Truncate>{virtualAccount.title}</Table.Truncate>,
    }, {
      className: styles.table__id,
      key: 'id',
      title: lang.get('businessAccount.virtualAccount.list.id'),
      render: (_, virtualAccount) => (
        <span title={virtualAccount.id}>
          {truncate(virtualAccount.id, { length: config.TABLE_ID_MAX_LENGTH })}
        </span>
      ),
    }, {
      className: styles.table__number,
      key: 'number',
      title: lang.get('businessAccount.virtualAccount.list.number'),
      render: (_, virtualAccount) => virtualAccount.externalId,
    }, {
      key: 'status',
      title: lang.get('businessAccount.virtualAccount.list.status'),
      render: (_, virtualAccount) => <VirtualAccountStatus status={virtualAccount.externalStatus} />,
    },
  ];

  const virtualAccount = virtualAccountsQuery.data?.find((virtualAccount) => virtualAccount.id === virtualAccountId);

  return (
    <TableView
      icon={<BusinessAccountIcon businessAccountType={businessAccount.type} size="large" />}
      title={lang.get('businessAccount.virtualAccount.list.title', { name: businessAccount.name })}
    >

      <Table<BusinessAccountVirtualAccount>
        columns={columns}
        dataSource={virtualAccountsQuery.data ?? []}
        rowKey={(virtualAccount) => virtualAccount.id}
        loading={virtualAccountsQuery.isLoading}
        clickable
        onRow={(virtualAccount) => ({ onClick: handleViewClick(virtualAccount) })}
      />

      {virtualAccount && (
        <Modal
          businessAccount={businessAccount}
          virtualAccount={virtualAccount}
          open={modal.open}
          onClose={modal.hide}
        />
      )}

    </TableView>
  );
};

export default MembersView;

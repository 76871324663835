import React from 'react';
import { useNavigate } from 'react-router';

import { url } from 'helpers';
import { useLang } from 'hooks';
import { useCorridorTypesQuery } from 'hooks/queries';
import { Can, CardView } from 'components/layout';
import { Button } from 'components/ui';
import { UserPermission } from 'types/models';

const TypesView = () => {
  const lang = useLang();
  const navigate = useNavigate();

  const typesQuery = useCorridorTypesQuery();

  return (
    <Can permissions={[
      UserPermission.CURRENCY_CORRIDOR_TRANSACTION_TYPES_VIEW,
      UserPermission.CURRENCY_CORRIDOR_TRANSACTION_TYPES_UPDATE,
    ]}>
      <CardView
        title={lang.get('corridorType.card.title')}
        caption={lang.choice('corridorType.card.caption', typesQuery.data?.total ?? 0)}
        actions={(
          <Button color="primary" variant="filled" onClick={() => navigate(url.toCorridorTypes())}>
            {lang.get('common.actions.view')}
          </Button>
        )}
      />
    </Can>
  );
};

export default TypesView;

import React, { FC, useEffect } from 'react';
import map from 'lodash/map';

import { config, yup } from 'data';
import { businessAccountService, corridorTypeService } from 'services';
import { useAuth, useForm, useFormWatch, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { useCorridorFormatter, useTransactionFormatter } from 'hooks/formatters';
import { useAllCorridorTypesQuery, useBusinessAccountDetailsQuery } from 'hooks/queries';
import { Checkbox, Form, Grid, Modal, Paragraph, Space } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { BusinessAccount, CorridorTypeLevel, TransactionType } from 'types/models';
import { BusinessAccountUpdateDetailsParams } from 'types/services';

import styles from './styles.module.css';

type FormValues = Pick<BusinessAccountUpdateDetailsParams, 'corridorIds'>;

const initialValues: FormValues = {
  //
};

const validationSchema = yup.object({
  corridorIds: yup.array().required().of(
    yup.string().required().uuid(),
  ),
});

type CorridorsModalProps = ModalBaseProps & {
  businessAccount: BusinessAccount;
};

const CorridorsModal: FC<CorridorsModalProps> = ({
  businessAccount,
  open,
  onClose,
}) => {
  const auth = useAuth();
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();
  const corridorFormatter = useCorridorFormatter();
  const transactionFormatter = useTransactionFormatter();

  const currentCorridorIds = useFormWatch('corridorIds', form);

  const businessAccountDetailsQuery = useBusinessAccountDetailsQuery(businessAccount.id);
  const corridorTypesQuery = useAllCorridorTypesQuery({ level: CorridorTypeLevel.CLIENT });

  const updateBusinessAccountDetailsMutation = useMutation({
    mutationFn: (values: FormValues) => businessAccountService.updateBusinessAccountDetails(businessAccount.id, values),
    onSuccess: () => queryInvalidate([config.BUSINESS_ACCOUNT_DETAILS_QUERY_KEY, businessAccount.id]),
    successNotification: lang.get('businessAccount.corridors.modal.success', { name: businessAccount.name }),
  });

  const handleSubmit = async (values: FormValues) => {
    await updateBusinessAccountDetailsMutation.mutateAsync(values);

    onClose();
  };

  useEffect(() => {
    if (open && businessAccountDetailsQuery.data) {
      form.setFieldsValue({ corridorIds: map(businessAccountDetailsQuery.data.corridors, 'id') });
    }
  }, [open, form, businessAccountDetailsQuery.data]);

  const canEdit = auth.can(...businessAccountService.getBusinessAccountEditPermissions(businessAccount));
  const corridorTypeGroups = corridorTypeService.groupTypes(corridorTypesQuery.data ?? []);

  return (
    <Modal
      title={lang.get('businessAccount.corridors.modal.title', { name: businessAccount.name })}
      caption={lang.choice('businessAccount.corridors.modal.caption', currentCorridorIds?.length ?? 0)}
      okText={lang.get('common.actions.save')}
      cancelText={!canEdit ? lang.get('common.actions.close') : null}
      okButtonProps={{ hidden: !canEdit }}
      open={open}
      confirmLoading={updateBusinessAccountDetailsMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        disabled={!canEdit}
        onFinish={handleSubmit}
      >
        <Form.Item name="corridorIds">
          <Checkbox.Group>
            <Grid.Row gutter={[24, 24]}>
              {Object.entries(corridorTypeGroups).map(([type, corridorTypes]) => (
                <Grid.Col key={type} span={12}>
                  <Paragraph className={styles.corridors__label}>{transactionFormatter.formatType(type as TransactionType)}</Paragraph>
                  <Space direction="vertical">
                    {corridorTypes.map((corridorType) => (
                      <Checkbox key={corridorType.id} value={corridorType.id}>
                        {corridorFormatter.format(corridorType.currencyCorridor.country, corridorType.currencyCorridor.currency)}
                      </Checkbox>
                    ))}
                  </Space>
                </Grid.Col>
              ))}
            </Grid.Row>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CorridorsModal;

import React, { FC, useEffect } from 'react';
import { Link } from 'react-router';

import { config, moment, yup } from 'data';
import { url } from 'helpers';
import { useForm, useFormWatch, useLang } from 'hooks';
import { useClientVerificationDetailsQuery } from 'hooks/queries';
import { CountrySelect, FeeCommissionSelect } from 'components/selects';
import { Checkbox, DatePicker, Form, Input, Modal, Select } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { ClientVerifyParams, FeeCommissionsParams } from 'types/services';

import {
  BusinessApplicationFinancialSettings,
  Client,
  ClientAddress,
  ClientGender,
  ClientIdType,
  FeeCommissionLevel,
  TransactionType,
} from 'types/models';

import styles from './styles.module.css';

type FormFinancialSettings = BusinessApplicationFinancialSettings & {
  defaultFallBelowFee: boolean;
  defaultServiceFee: boolean;
  defaultVaOpenFee: boolean;
};

type FormValues = ClientVerifyParams & {
  financialSettings: FormFinancialSettings;
};

const genders = Object.values(ClientGender);
const idTypes = Object.values(ClientIdType);

const initialAddress: ClientAddress = {
  countryCode: '',
  state: '',
  city: '',
  street: '',
  postalCode: '',
};

const initialFinancialSettings: FormFinancialSettings = {
  lowBalanceLevel: 0,
  fallBelowFeeId: null,
  serviceFeeId: null,
  vaOpenFeeId: null,
  defaultFallBelowFee: false,
  defaultServiceFee: false,
  defaultVaOpenFee: false,
};

const initialValues: Omit<FormValues, 'idType' | 'gender'> = {
  customerId: '',
  dateOfBirth: '',
  issuanceCountryCode: '',
  nationalityCountryCode: '',
  address: initialAddress,
  financialSettings: initialFinancialSettings,
};

const addressValidationSchema = yup.object({
  countryCode: yup.string().required().country(),
  state: yup.string().required().min(config.STATE_MIN_LENGTH).max(config.STATE_MAX_LENGTH),
  city: yup.string().required().min(config.CITY_MIN_LENGTH).max(config.CITY_MAX_LENGTH),
  street: yup.string().required().min(config.STREET_MIN_LENGTH).max(config.STREET_MAX_LENGTH),
  postalCode: yup.string().required().min(config.ZIP_MIN_LENGTH).max(config.ZIP_MAX_LENGTH),
});

const financialSettingsValidationSchema = yup.object({
  lowBalanceLevel: yup.number().required().decimal().min(0).transform((value) => value || 0),
  fallBelowFeeId: yup
    .string()
    .required()
    .uuid()
    .default(initialFinancialSettings.fallBelowFeeId)
    .when('defaultFallBelowFee', ([useDefault], schema) => useDefault ? schema.notRequired() : schema),
  serviceFeeId: yup
    .string()
    .required()
    .uuid()
    .default(initialFinancialSettings.serviceFeeId)
    .when('defaultServiceFee', ([useDefault], schema) => useDefault ? schema.notRequired() : schema),
  vaOpenFeeId: yup
    .string()
    .required()
    .uuid()
    .default(initialFinancialSettings.vaOpenFeeId)
    .when('defaultVaOpenFee', ([useDefault], schema) => useDefault ? schema.notRequired() : schema),
  defaultFallBelowFee: yup.boolean().required().default(initialFinancialSettings.defaultFallBelowFee),
  defaultServiceFee: yup.boolean().required().default(initialFinancialSettings.defaultServiceFee),
  defaultVaOpenFee: yup.boolean().required().default(initialFinancialSettings.defaultVaOpenFee),
});

const validationSchema = yup.object({
  idType: yup.string().required().oneOf(idTypes),
  customerId: yup.string().required().max(config.STRING_MAX_LENGTH),
  gender: yup.string().required().oneOf(genders),
  dateOfBirth: yup.string().required(),
  issuanceCountryCode: yup.string().required().country(),
  nationalityCountryCode: yup.string().required().country(),
  address: addressValidationSchema,
  financialSettings: financialSettingsValidationSchema,
});

const fallBelowFeeParams: FeeCommissionsParams = {
  level: [FeeCommissionLevel.CLIENT],
  transactionType: [TransactionType.FALL_BELOW_FEE],
};

const serviceFeeParams: FeeCommissionsParams = {
  level: [FeeCommissionLevel.CLIENT],
  transactionType: [TransactionType.SERVICE_FEE],
};

const vaOpenFeeParams: FeeCommissionsParams = {
  level: [FeeCommissionLevel.CLIENT],
  transactionType: [TransactionType.VA_OPEN],
};

type VerifyClientModalProps = ModalBaseProps & {
  client: Client;
  loading?: boolean;
  onSubmit: (values: FormValues) => Promise<void>;
};

const VerifyClientModal: FC<VerifyClientModalProps> = ({
  client,
  open,
  loading,
  onClose,
  onSubmit,
}) => {
  const form = useForm<FormValues>();
  const lang = useLang();

  const currentDefaultFallBelowFee = useFormWatch(['financialSettings', 'defaultFallBelowFee'], form);
  const currentDefaultServiceFee = useFormWatch(['financialSettings', 'defaultServiceFee'], form);
  const currentDefaultVaOpenFee = useFormWatch(['financialSettings', 'defaultVaOpenFee'], form);

  const detailsQuery = useClientVerificationDetailsQuery(client.id);

  const handleSubmit = async (values: FormValues) => {
    await onSubmit(values);

    onClose();
  };

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        ...initialValues,
        ...detailsQuery.data,
      } as FormValues);
    }
  }, [open, form, detailsQuery.data]);

  useEffect(() => {
    if (form.isFieldTouched(['financialSettings', 'defaultFallBelowFee'])) {
      form.resetFields([['financialSettings', 'fallBelowFeeId']]);
    }
  }, [form, currentDefaultFallBelowFee]);

  useEffect(() => {
    if (form.isFieldTouched(['financialSettings', 'defaultServiceFee'])) {
      form.resetFields([['financialSettings', 'serviceFeeId']]);
    }
  }, [form, currentDefaultServiceFee]);

  useEffect(() => {
    if (form.isFieldTouched(['financialSettings', 'defaultVaOpenFee'])) {
      form.resetFields([['financialSettings', 'vaOpenFeeId']]);
    }
  }, [form, currentDefaultVaOpenFee]);

  return (
    <Modal
      title={lang.get('client.verifyModal.title', { email: client.email })}
      caption={lang.get('client.verifyModal.caption')}
      okText={lang.get('common.actions.verify')}
      width="small"
      open={open}
      confirmLoading={loading}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        disabled={detailsQuery.isLoading}
        onFinish={handleSubmit}
      >

        <Form.Title>{lang.get('client.verifyModal.id')}</Form.Title>
        <Form.Columns>
          <Form.Item name="idType" label={lang.get('client.verifyModal.idType.label')}>
            <Select
              placeholder={lang.get('client.verifyModal.idType.placeholder')}
              options={idTypes.map((type) => ({
                value: type,
                label: lang.get(`client.idTypes.${type.toLowerCase()}`),
              }))}
            />
          </Form.Item>
          <Form.Item name="customerId" label={lang.get('client.verifyModal.idNumber.label')}>
            <Input placeholder={lang.get('client.verifyModal.idNumber.placeholder')} />
          </Form.Item>
        </Form.Columns>
        <Form.Item name="issuanceCountryCode" label={lang.get('client.verifyModal.idCountry.label')}>
          <CountrySelect />
        </Form.Item>

        <Form.Divider />

        <Form.Title>{lang.get('client.verifyModal.info')}</Form.Title>
        <Form.Columns>
          <Form.Item name="gender" label={lang.get('client.verifyModal.gender.label')}>
            <Select
              placeholder={lang.get('client.verifyModal.gender.placeholder')}
              options={genders.map((gender) => ({
                value: gender,
                label: lang.get(`client.genders.${gender.toLowerCase()}`),
              }))}
            />
          </Form.Item>
          <Form.Item name="dateOfBirth" label={lang.get('common.form.birthday.label')}>
            <DatePicker maxDate={moment().subtract(1, 'day').endOf('day')} allowClear={false} />
          </Form.Item>
        </Form.Columns>
        <Form.Item name="nationalityCountryCode" label={lang.get('client.verifyModal.country.label')}>
          <CountrySelect />
        </Form.Item>

        <Form.Divider />

        <Form.Title>{lang.get('client.verifyModal.address')}</Form.Title>
        <Form.Columns>
          <Form.Item name={['address', 'countryCode']} label={lang.get('common.form.country.label')}>
            <CountrySelect />
          </Form.Item>
          <Form.Item name={['address', 'state']} label={lang.get('common.form.state.label')}>
            <Input placeholder={lang.get('common.form.state.placeholder')} />
          </Form.Item>
        </Form.Columns>
        <Form.Columns>
          <Form.Item name={['address', 'city']} label={lang.get('common.form.city.label')}>
            <Input placeholder={lang.get('common.form.city.placeholder')} />
          </Form.Item>
          <Form.Item name={['address', 'postalCode']} label={lang.get('common.form.zip.label')}>
            <Input placeholder={lang.get('common.form.zip.placeholder')} />
          </Form.Item>
        </Form.Columns>
        <Form.Item name={['address', 'street']} label={lang.get('common.form.street.label')}>
          <Input placeholder={lang.get('common.form.street.placeholder')} />
        </Form.Item>

        <Form.Divider />

        <Form.Title>{lang.get('client.verifyModal.financialSettings')}</Form.Title>
        <Form.Item label={lang.get('client.verifyModal.vaOpenFee.label')}>
          <Form.Item
            className={styles.modal__fee__input}
            name={['financialSettings', 'vaOpenFeeId']}
            extra={(
              <Link to={url.toFeeManagement(undefined, url.prepareFilterParams(vaOpenFeeParams))}>
                {lang.get('client.verifyModal.manageFee')}
              </Link>
            )}
            hidden={currentDefaultVaOpenFee}
          >
            <FeeCommissionSelect params={vaOpenFeeParams} />
          </Form.Item>
          <Form.CheckboxItem
            className={styles.modal__fee__checkbox}
            name={['financialSettings', 'defaultVaOpenFee']}
          >
            <Checkbox>{lang.get('client.verifyModal.useDefault')}</Checkbox>
          </Form.CheckboxItem>
        </Form.Item>
        <Form.Item label={lang.get('client.verifyModal.serviceFee.label')}>
          <Form.Item
            className={styles.modal__fee__input}
            name={['financialSettings', 'serviceFeeId']}
            extra={(
              <Link to={url.toFeeManagement(undefined, url.prepareFilterParams(serviceFeeParams))}>
                {lang.get('client.verifyModal.manageFee')}
              </Link>
            )}
            hidden={currentDefaultServiceFee}
          >
            <FeeCommissionSelect params={serviceFeeParams} />
          </Form.Item>
          <Form.CheckboxItem
            className={styles.modal__fee__checkbox}
            name={['financialSettings', 'defaultServiceFee']}
          >
            <Checkbox>{lang.get('client.verifyModal.useDefault')}</Checkbox>
          </Form.CheckboxItem>
        </Form.Item>
        <Form.Item label={lang.get('client.verifyModal.fallBelowFee.label')}>
          <Form.Item
            className={styles.modal__fee__input}
            name={['financialSettings', 'fallBelowFeeId']}
            extra={(
              <Link to={url.toFeeManagement(undefined, url.prepareFilterParams(fallBelowFeeParams))}>
                {lang.get('client.verifyModal.manageFee')}
              </Link>
            )}
            hidden={currentDefaultFallBelowFee}
          >
            <FeeCommissionSelect params={fallBelowFeeParams} />
          </Form.Item>
          <Form.CheckboxItem
            className={styles.modal__fee__checkbox}
            name={['financialSettings', 'defaultFallBelowFee']}
          >
            <Checkbox>{lang.get('client.verifyModal.useDefault')}</Checkbox>
          </Form.CheckboxItem>
        </Form.Item>
        <Form.Item name={['financialSettings', 'lowBalanceLevel']} label={lang.get('client.verifyModal.lowBalanceLevel.label')}>
          <Input.Number placeholder={lang.get('client.verifyModal.lowBalanceLevel.placeholder')} suffix={config.DEFAULT_CURRENCY} />
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default VerifyClientModal;

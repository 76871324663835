import { Nullable, Uuid } from './common';

export enum ActivityStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum AuditLogAction {
  LOG_IN = 'log-in',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

export enum AuditLogObject {
  CLIENT = 'client',
  BA_APPLICATION = 'ba-application',
  CORRIDOR = 'corridor',
  CORRIDOR_TYPE = 'corridor-type',
  COST = 'cost',
  CURRENCY = 'currency',
  CURRENCY_RATE = 'currency-rate',
  FEE_COMMISSION = 'fee-commission',
  FEE_GROUP = 'fee-group',
  PROVIDER = 'provider',
  ROLE = 'role',
  BUNDLE = 'bundle',
  USER = 'user',
}

export enum BusinessAccountApiStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum BusinessAccountRoleId {
  'OWNER' = 'ROLE_OWNER',
  'ACCOUNTANT' = 'ROLE_ACCOUNTANT',
  'ANALYST' = 'ROLE_ANALYST',
}

export enum BusinessAccountStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum BusinessAccountType {
  COMPANY = 'COMPANY',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum BusinessApplicationStatus {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  IN_PROGRESS = 'IN_PROGRESS',
  REVIEW = 'REVIEW',
  PASSED = 'PASSED',
  FAILED = 'FAILED',
  ARCHIVED = 'ARCHIVED',
}

export enum BusinessApplicationKybStatus {
  AWAITING_DOCUMENTS = 'AWAITING_DOCUMENTS',
  AWAITING_QUESTIONARY = 'AWAITING_QUESTIONARY',
  DOCUMENTS_UPLOADED = 'DOCUMENTS_UPLOADED',
  FAILED = 'FAILED',
  NEW = 'NEW',
  PASSED = 'PASSED',
  REVIEW = 'REVIEW',
  REJECTED = 'REJECTED',
  QUESTIONARY_PASSED = 'QUESTIONARY_PASSED',
}

export enum ClientGender {
  FEMALE = 'F',
  MALE = 'M',
}

export enum ClientIdType {
  DRIVING_LICENSE = 'Driving License',
  ID_CARD = 'ID Card',
  PASSPORT = 'Passport',
}

export enum ClientStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum CorridorTypeLevel {
  GLOBAL = 'GLOBAL',
  CLIENT = 'CLIENT',
}

export enum FeeCommissionFrequency {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  ANNUALLY = 'ANNUALLY',
}

export enum FeeCommissionLevel {
  GLOBAL = 'GLOBAL',
  GROUP = 'GROUP',
  CLIENT = 'CLIENT',
}

export enum ProviderFrequency {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  ANNUALLY = 'annually',
}

export enum ReportProvidersDailySettlementStatus {
  EMPTY = 'EMPTY',
  PARTIALLY = 'PARTIALLY',
  COMPLETED = 'COMPLETED',
}

export enum TransactionBundleStatus {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
  FAILED = 'FAILED',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
}

export enum TransactionDirection {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
}

export enum TransactionFeeType {
  FIXED = 'FIXED',
  PERCENT = 'PERCENT',
}

export enum TransactionPayoutMethod {
  ACCOUNT_CREDIT = 'ACCOUNT_CREDIT',
  CASH_PICKUP = 'CASH_PICKUP',
  DIGITAL_WALLET = 'DIGITAL_WALLET',
}

export enum TransactionStatus {
  COMPLETED = 'completed',
  PENDING = 'pending',
  CANCELED = 'canceled',
  FAILED = 'failed',
  INSUFFICIENT_FUNDS = 'insufficient-funds',
}

export enum TransactionType {
  VA_OPEN = 'VA_OPEN',
  ADJUSTMENT = 'ADJUSTMENT',
  EXTERNAL_CREDIT = 'EXTERNAL_CREDIT',
  EXTERNAL = 'EXTERNAL',
  FALL_BELOW_FEE = 'FALL_BELOW_FEE',
  FASTPAY = 'FASTPAY',
  EXCHANGE = 'EXCHANGE',
  GLOBESEND = 'GLOBESEND',
  P2P = 'P2P',
  PAYNOW = 'PAYNOW',
  SERVICE_FEE = 'SERVICE_FEE',
  TT = 'TT',
}

export enum TransactionTypeCategory {
  BASE = 'BASE',
  PLANETX = 'PLANETX',
  PHOENIX = 'PHOENIX',
}

export enum UserPermission {
  AUDIT_LOGS_VIEW = 'AUDIT_LOGS_VIEW',
  BA_VIEW = 'BA_VIEW',
  BA_UPDATE = 'BA_UPDATE',
  BA_REPORTS_VIEW = 'BA_REPORTS_VIEW',
  BA_TRX_VIEW = 'BA_TRX_VIEW',
  BA_TRX_UPDATE = 'BA_TRX_UPDATE',
  BA_APPLICATIONS_VIEW = 'BA_APPLICATIONS_VIEW',
  BA_APPLICATIONS_MGMT = 'BA_APPLICATIONS_MGMT',
  COSTS_VIEW = 'COSTS_VIEW',
  COSTS_UPDATE = 'COSTS_UPDATE',
  CURRENCY_CORRIDORS_VIEW = 'CURRENCY_CORRIDORS_VIEW',
  CURRENCY_CORRIDORS_UPDATE = 'CURRENCY_CORRIDORS_UPDATE',
  CURRENCY_CORRIDOR_TRANSACTION_TYPES_VIEW = 'CURRENCY_CORRIDOR_TRANSACTION_TYPES_VIEW',
  CURRENCY_CORRIDOR_TRANSACTION_TYPES_UPDATE = 'CURRENCY_CORRIDOR_TRANSACTION_TYPES_UPDATE',
  CURRENCY_RATES_VIEW = 'CURRENCY_RATES_VIEW',
  CURRENCY_RATES_UPDATE = 'CURRENCY_RATES_UPDATE',
  FEE_COMMISSIONS_VIEW = 'FEE_COMMISSIONS_VIEW',
  FEE_COMMISSIONS_UPDATE = 'FEE_COMMISSIONS_UPDATE',
  FEE_GROUPS_VIEW = 'FEE_GROUPS_VIEW',
  FEE_GROUPS_UPDATE = 'FEE_GROUPS_UPDATE',
  INDIVIDUALS_VIEW = 'INDIVIDUALS_VIEW',
  INDIVIDUALS_UPDATE = 'INDIVIDUALS_UPDATE',
  INDIVIDUALS_REPORTS_VIEW = 'INDIVIDUALS_REPORTS_VIEW',
  INDIVIDUALS_TRX_VIEW = 'INDIVIDUALS_TRX_VIEW',
  INDIVIDUALS_TRX_UPDATE = 'INDIVIDUALS_TRX_UPDATE',
  PROVIDERS_VIEW = 'PROVIDERS_VIEW',
  PROVIDERS_UPDATE = 'PROVIDERS_UPDATE',
  REPORTS_VIEW = 'REPORTS_VIEW',
  REPORTS_SETTLEMENT_MGMT = 'REPORTS_SETTLEMENT_MGMT',
  TRX_VIEW = 'TRX_VIEW',
  TRX_UPDATE = 'TRX_UPDATE',
  TRX_BUNDLES_VIEW = 'TRX_BUNDLES_VIEW',
  TRX_BUNDLES_MGMT = 'TRX_BUNDLES_MGMT',
  TRX_BUNDLES_UPLOAD = 'TRX_BUNDLES_UPLOAD',
  USERS_VIEW = 'USERS_VIEW',
  USERS_UPDATE = 'USERS_UPDATE',
  USER_ROLES_VIEW = 'USER_ROLES_VIEW',
  USER_ROLES_UPDATE = 'USER_ROLES_UPDATE',
}

export enum VerificationStatus {
  VERIFIED = 'VERIFIED',
  UNVERIFIED = 'UNVERIFIED',
}

export enum VirtualAccountStatus {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
}

type Model<Params> = Params & {
  id: Uuid;
  createdAt: string;
  updatedAt: Nullable<string>;
};

export type AuditLog = {
  id: Uuid;
  objectId: Nullable<Uuid>;
  objectType: AuditLogObject;
  action: AuditLogAction;
  oldValue: Nullable<object>;
  newValue: Nullable<object>;
  createdAt: string;
  creator: UserBasic;
};

export type BusinessAccount = {
  id: Uuid;
  name: string;
  type: BusinessAccountType;
  status: BusinessAccountStatus;
  enabledApi: boolean;
  lastLoginAt: Nullable<string>;
  balance?: BusinessAccountBalance;
};

export type BusinessAccountBasic = Pick<BusinessAccount, 'id' | 'name' | 'type'>;

export type BusinessAccountExtended = BusinessAccount & {
  email: string;
  verification: Nullable<Verification>;
};

export type BusinessAccountBalance = {
  id: Uuid;
  totalBalance: number;
  totalIncomingPendingBalance: number;
  totalOutgoingPendingBalance: number;
  currency: CurrencyCode;
  details: Array<{
    virtualAccountId: Uuid;
    externalId: string;
    title: string;
    balances: Array<{
      balance: number;
      incomingPendingBalance: number;
      outgoingPendingBalance: number;
      currency: CurrencyCode;
    }>;
  }>;
};

export type BusinessAccountDetails = {
  id: Uuid;
  corridors: Nullable<CorridorType[]>;
  feeCommissions: Nullable<FeeCommissionSimple[]>;
  feeGroupId?: Nullable<Uuid>;
  lowBalanceLevel: Nullable<number>;
  webhookUrl: Nullable<string>;
};

export type BusinessAccountMember = Client & {
  roles: BusinessAccountRole[];
};

export type BusinessAccountRole = {
  id: BusinessAccountRoleId;
  name: string;
  description: Nullable<string>;
};

export type BusinessAccountVirtualAccount = {
  id: Uuid;
  externalId: string;
  externalStatus: VirtualAccountStatus;
  title: string;
  balances: Array<{
    balance: number;
    outgoingPendingBalance: number;
    incomingPendingBalance: number;
    currency: Pick<Currency, 'code' | 'name'>;
  }>;
};

export type BusinessApplication = Model<{
  name: string;
  email: string;
  activity: string;
  mainIndustry?: Nullable<BusinessApplicationIndustry>;
  registryDate: Nullable<string>;
  rejectReason: Nullable<string>;
  status: BusinessApplicationStatus;
  isRegistryAddressSameAsPrincipal: boolean;
  financialSettings: Nullable<BusinessApplicationFinancialSettings>;
  kybDetails: Nullable<{
    id: Uuid;
    detailsLink: Nullable<string>;
    externalCode: Nullable<string>;
    status: BusinessApplicationKybStatus;
  }>;
  principalAddress: Nullable<BusinessApplicationAddress>;
  registryAddress: Nullable<BusinessApplicationAddress>;
}>;

export type BusinessApplicationAddress = {
  countryCode: CountryCode;
  state: Nullable<string>;
  city: Nullable<string>;
  street: Nullable<string>;
  zip: Nullable<string>;
};

export type BusinessApplicationFinancialSettings = {
  fallBelowFeeId?: Nullable<Uuid>;
  lowBalanceLevel: number;
  serviceFeeId?: Nullable<Uuid>;
  vaOpenFeeId?: Nullable<Uuid>;
};

export type BusinessApplicationIndustry = {
  id: Uuid;
  value: string;
};

export type Client = {
  id: Uuid;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  email: string;
  phone: Nullable<string>;
  birthdate: string;
  residenceCountryCode: Nullable<string>;
  status: ClientStatus;
  lastLoginAt: Nullable<string>;
  verification: Nullable<Verification>;
};

export type ClientAddress = {
  countryCode: Nullable<CountryCode>;
  state: Nullable<string>;
  city: Nullable<string>;
  street: Nullable<string>;
  postalCode: Nullable<string>;
};

export type ClientVerificationDetails = {
  issuanceCountryCode: Nullable<CountryCode>;
  nationalityCountryCode: Nullable<CountryCode>;
  dateOfBirth: Nullable<string>;
  idType: Nullable<ClientIdType>;
  customerId: Nullable<string>;
  gender: Nullable<ClientGender>;
  address: ClientAddress;
  financialSettings: Nullable<BusinessApplicationFinancialSettings>;
};

export type Corridor = {
  id: Uuid;
  country: CountryCode;
  currency: CurrencyCode;
  assigned: boolean;
};

export type CorridorType = {
  id: Uuid;
  level: CorridorTypeLevel;
  type: TransactionType;
  active: boolean;
  assigned: boolean;
  currencyCorridor: Corridor;
};

export type Cost = {
  id: Uuid;
  fixedTransactionFee: number;
  percentTransactionFee: number;
  minTransactionFeeAmount: number;
  maxTransactionFeeAmount: number;
  fxMarkup: number;
  startDate?: Nullable<string>;
  endDate?: Nullable<string>;
  notes: Nullable<string>;
  active: boolean;
  c2c: boolean;
  c2b: boolean;
  b2c: boolean;
  b2b: boolean;
  corridor: CorridorType;
  provider: {
    id: Uuid;
    name: string;
    currency: CurrencyCode;
  };
};

export type Country = {
  code: CountryCode;
  name: string;
};

export type CountryCode = string;

export type Currency = {
  code: CurrencyCode;
  name: string;
  active: boolean;
};

export type CurrencyCode = string;

export type CurrencyRate = {
  id: Uuid;
  baseCurrency: CurrencyCode;
  targetCurrency: CurrencyCode;
  rate: number;
  date: string;
};

export type FeeCommission = Model<{
  copyId: Nullable<Uuid>;
  sourceId: Nullable<Uuid>;
  feeGroupId?: Nullable<Uuid>;
  name: string;
  level: FeeCommissionLevel;
  direction: Nullable<TransactionDirection>;
  feeFrequency: Nullable<FeeCommissionFrequency>;
  transactionFeeCurrency: CurrencyCode;
  fixedTransactionFee: number;
  percentTransactionFee: number;
  minTransactionFeeAmount: number;
  maxTransactionFeeAmount: number;
  minRangeFeeAmount: number;
  maxRangeFeeAmount: number;
  fxMarkup: number;
  startDate: Nullable<string>;
  endDate: Nullable<string>;
  description: Nullable<string>;
  active: boolean;
  assigned: boolean;
  isSameCurrency: Nullable<boolean>;
  corridor: CorridorType;
  ranges: FeeCommissionRange[];
}>;

export type FeeCommissionSimple = {
  id: Uuid;
  name: string;
};

export type FeeCommissionRange = {
  amountFrom: number;
  amountTo?: Nullable<number>;
  percentTransactionFee: number;
};

export type FeeGroup = Model<{
  name: string;
  description: string;
  assigned: boolean;
}>;

export type Profile = {
  id: Uuid;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  email: string;
  phone: Nullable<string>;
};

export type Provider = ProviderBasic & {
  currency: CurrencyCode;
  balance: Nullable<{
    available: number;
    pending: Nullable<number>;
    total: Nullable<number>;
    currency: CurrencyCode;
  }>;
};

export type ProviderBasic = {
  id: Uuid;
  name: string;
  active: boolean;
};

export type ProviderExtended = ProviderBasic & {
  currency: CurrencyCode;
  frequency?: Nullable<ProviderFrequency>;
  minFeeAmount: number;
  notes?: Nullable<string>;
  files: UploadedFile[];
};

export type ReportBusinessAccountsBalancesItem = {
  available: number;
  outgoingPending: number;
  incomingPending: number;
  total: number;
  currency: CurrencyCode;
};

export type ReportBusinessAccountsDailyBalanceItem = {
  client: BusinessAccountBasic;
  available: number;
  outgoingPending: number;
  incomingPending: number;
  total: number;
  currency: CurrencyCode;
  date: string;
};

export type ReportBusinessAccountsDailyRevenueItem = {
  totalAmount: number;
  totalTransactionFees: number;
  totalProviderFees: number;
  totalTransactions: number;
  totalFxFees: {
    amount: number;
    percent: number;
  };
  netIncome: number;
  payoutMethod: TransactionPayoutMethod;
  country: CountryCode;
  currency: CurrencyCode;
  date: string;
  client: BusinessAccountBasic;
  provider: {
    id: Uuid;
    name: string;
  };
};

export type ReportDailyBalanceItem = {
  startingBalance: number;
  endingBalance: number;
  totalAmount: number;
  totalProviderFees: number;
  totalTransactionFees: number;
  currency: CurrencyCode;
  date: string;
};

export type ReportDailyRemittanceItem = {
  bundles: number;
  acceptedTransactions: number;
  rejectedTransactions: number;
  remittedAmount: number;
  rejectedAmount: number;
  remittedFxFees: number;
  remittedTransactionFees: number;
  currency: CurrencyCode;
  date: string;
};

export type ReportDailyTransactionItem = {
  totalTransactions: number;
  totalAmount: number;
  totalProviderFees: number;
  totalTransactionFees: number;
  currency: CurrencyCode;
  date: string;
};

export type ReportProvidersDailyBalanceItem = {
  provider: string;
  available: number;
  pending: Nullable<number>;
  total: Nullable<number>;
  currency: CurrencyCode;
  date: string;
};

export type ReportProvidersDailySettlementItem = {
  totalAmount: number;
  totalProviderFees: number;
  totalTransactions: number;
  grossTransactionFees: number;
  netClientPayment: number;
  netIncome: number;
  currency: CurrencyCode;
  date: string;
  status: ReportProvidersDailySettlementStatus;
  client: BusinessAccountBasic;
  provider: ProviderBasic;
  transactionIds: Uuid[];
};

export type Topic = {
  id: Uuid;
  title: string;
};

export type Transaction = Model<{
  flowId?: Nullable<Uuid>;
  externalId: Nullable<string>;
  providerReferenceId: Nullable<string>;
  clientReferenceId: Nullable<string>;
  payoutMethod: Nullable<TransactionPayoutMethod>;
  transactionType: TransactionType;
  beneficiary?: Nullable<string>;
  country: Nullable<CountryCode>;
  originAmount: number;
  originCurrency: CurrencyCode;
  destinationAmount: number;
  destinationCurrency: CurrencyCode;
  balance: number;
  previousBalance: number;
  currencyRate: number;
  effectiveRate: number;
  transactionFee: number;
  fxMarkupFee: number;
  providerFee: number;
  status: TransactionStatus;
  errorMsg?: Nullable<string>;
  notes?: Nullable<string>;
  statusChangedAt: Nullable<string>;
  client: BusinessAccountBasic;
  createdBy: Nullable<UserBasic>;
  updatedBy: Nullable<UserBasic>;
  beneficiaryDetails: Nullable<Partial<TransactionMetadataSender & TransactionMetadataReceiver>>;
  feeCommission: Nullable<{
    id: Uuid;
    fxMarkup: number;
  }>;
  metadata: Nullable<{
    msgId: string;
    bankReference: string;
    txnRefId: string;
    paymentDetails: Nullable<string>;
    additionalInfo: Nullable<string>;
    request: object;
    response: object;
    senderParty: Nullable<TransactionMetadataSender>;
    receivingParty: Nullable<TransactionMetadataReceiver>;
  }>;
  provider: Nullable<{
    id: Uuid;
    name: string;
  }>;
  securityInfo: Nullable<{
    deviceId: Nullable<string>;
    deviceName: Nullable<string>;
    ips: Nullable<string[]>;
    isPhysical: Nullable<boolean>;
    os: Nullable<string>;
    userAgent: Nullable<string>;
    xForwardedFor: Nullable<string[]>;
  }>;
  virtualAccount: Nullable<{
    id: Uuid;
    externalId: string;
    title: string;
  }>;
}>;

type TransactionMetadataSender = {
  name: Nullable<string>;
  bankId: Nullable<string>;
  bankName: string;
  bankCode: string;
  branchCode: string;
  accountNo: Nullable<string>;
};

type TransactionMetadataReceiver = {
  name: Nullable<string>;
  bankId: Nullable<string>;
  bankCountryCode: CountryCode;
  swiftBic: Nullable<string>;
  accountNo: Nullable<string>;
  proxyType: string;
  proxyValue: string;
};

export type TransactionBundle = Model<{
  externalId: string;
  status: TransactionBundleStatus;
  client: BusinessAccountBasic;
}>;

export type TransactionBundleExtended = TransactionBundle & {
  errorText: string;
  file: UploadedFile;
};

export type UploadedFile = Model<{
  fileName: string;
  originFileName: string;
  bucketUrl: string;
}>;

export type User = {
  id: Uuid;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  email: string;
  phone: Nullable<string>;
  lastLoginAt: Nullable<string>;
  roles: UserRole[];
};

export type UserBasic = {
  id: Uuid;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  email: string;
};

export type UserRole = {
  id: Uuid;
  name: string;
  description: Nullable<string>;
  permissions: UserPermission[];
  assigned: boolean;
  isDefault: boolean;
};

export type Verification = {
  detailsLink: Nullable<string>;
  verified: boolean;
  createdAt: string;
  updatedAt: string;
  updatedBy: Nullable<{
    id: Uuid;
    firstName: Nullable<string>;
    lastName: Nullable<string>;
    email: string;
  }>;
};

import React, { FC, useEffect } from 'react';

import { config, yup } from 'data';
import { userService } from 'services';
import { useAuth, useForm, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { Trash } from 'components/icons';
import { Checkbox, Form, Grid, Input, Modal, Paragraph, PopconfirmButton, Space, TextArea } from 'components/ui';
import { Nullish } from 'types/common';
import { ModalBaseProps } from 'types/components';
import { UserPermission, UserRole } from 'types/models';
import { UserRoleParams } from 'types/services';

import styles from './styles.module.css';

type FormValues = UserRoleParams;

const initialValues: FormValues = {
  name: '',
  permissions: [],
};

const validationSchema = yup.object({
  name: yup.string().required().trim().max(config.STRING_MAX_LENGTH),
  description: yup.string().notRequired().trim().max(config.TEXT_MAX_LENGTH),
  permissions: yup.array().required().min(1).of(
    yup.string().required().oneOf(Object.values(UserPermission)),
  ),
});

const permissionGroups: Record<string, UserPermission[]> = {
  auditLogs: [
    UserPermission.AUDIT_LOGS_VIEW,
  ],
  businessAccounts: [
    UserPermission.BA_VIEW,
    UserPermission.BA_UPDATE,
    UserPermission.BA_REPORTS_VIEW,
    UserPermission.BA_TRX_VIEW,
    UserPermission.BA_TRX_UPDATE,
  ],
  businessApplications: [
    UserPermission.BA_APPLICATIONS_VIEW,
    UserPermission.BA_APPLICATIONS_MGMT,
  ],
  corridors: [
    UserPermission.CURRENCY_CORRIDORS_VIEW,
    UserPermission.CURRENCY_CORRIDORS_UPDATE,
  ],
  corridorTypes: [
    UserPermission.CURRENCY_CORRIDOR_TRANSACTION_TYPES_VIEW,
    UserPermission.CURRENCY_CORRIDOR_TRANSACTION_TYPES_UPDATE,
  ],
  costs: [
    UserPermission.COSTS_VIEW,
    UserPermission.COSTS_UPDATE,
  ],
  currencyRates: [
    UserPermission.CURRENCY_RATES_VIEW,
    UserPermission.CURRENCY_RATES_UPDATE,
  ],
  feeCommissions: [
    UserPermission.FEE_COMMISSIONS_VIEW,
    UserPermission.FEE_COMMISSIONS_UPDATE,
  ],
  feeGroups: [
    UserPermission.FEE_GROUPS_VIEW,
    UserPermission.FEE_GROUPS_UPDATE,
  ],
  individualAccounts: [
    UserPermission.INDIVIDUALS_VIEW,
    UserPermission.INDIVIDUALS_UPDATE,
    UserPermission.INDIVIDUALS_REPORTS_VIEW,
    UserPermission.INDIVIDUALS_TRX_VIEW,
    UserPermission.INDIVIDUALS_TRX_UPDATE,
  ],
  providers: [
    UserPermission.PROVIDERS_VIEW,
    UserPermission.PROVIDERS_UPDATE,
  ],
  reports: [
    UserPermission.REPORTS_VIEW,
    UserPermission.REPORTS_SETTLEMENT_MGMT,
  ],
  transactions: [
    UserPermission.TRX_VIEW,
    UserPermission.TRX_UPDATE,
  ],
  transactionBundles: [
    UserPermission.TRX_BUNDLES_VIEW,
    UserPermission.TRX_BUNDLES_MGMT,
    UserPermission.TRX_BUNDLES_UPLOAD,
  ],
  users: [
    UserPermission.USERS_VIEW,
    UserPermission.USERS_UPDATE,
  ],
  userRoles: [
    UserPermission.USER_ROLES_VIEW,
    UserPermission.USER_ROLES_UPDATE,
  ],
};

type UserRoleModalProps = ModalBaseProps & {
  role: Nullish<UserRole>;
};

const UserRoleModal: FC<UserRoleModalProps> = ({
  role,
  open,
  onClose,
}) => {
  const auth = useAuth();
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const roleId = role?.id ?? '';
  const roleName = role?.name ?? '';

  const invalidateRoleQueries = async () => {
    await queryInvalidate([config.USERS_QUERY_KEY]);
    await queryInvalidate([config.USER_ROLES_QUERY_KEY]);
    await queryInvalidate([config.USER_ROLE_QUERY_KEY, roleId]);
  };

  const createRoleMutation = useMutation({
    mutationFn: userService.createRole,
    onSuccess: invalidateRoleQueries,
    successNotification: lang.get('role.modal.createSuccess'),
  });

  const updateRoleMutation = useMutation({
    mutationFn: (values: FormValues) => userService.updateRole(roleId, values),
    onSuccess: invalidateRoleQueries,
    successNotification: lang.get('role.modal.updateSuccess', { name: roleName }),
  });

  const deleteRoleMutation = useMutation({
    mutationFn: () => userService.deleteRole(roleId),
    onSuccess: invalidateRoleQueries,
    successNotification: lang.get('role.modal.deleteSuccess', { name: roleName }),
  });

  const handleSubmit = async (values: FormValues) => {
    role
      ? await updateRoleMutation.mutateAsync(values)
      : await createRoleMutation.mutateAsync(values);

    onClose();
  };

  const handleDelete = async () => {
    await deleteRoleMutation.mutateAsync();

    onClose();
  };

  useEffect(() => {
    if (open && role) {
      form.setFieldsValue(role);
    }
  }, [role, open, form]);

  const canEdit = auth.can(UserPermission.USER_ROLES_UPDATE);
  const isEditing = Boolean(role);
  const isAssigned = Boolean(role?.assigned);
  const isDefault = Boolean(role?.isDefault);
  const isEditable = canEdit && !isDefault;
  const isDeletable = canEdit && isEditing && !isAssigned && !isDefault;

  return (
    <Modal
      title={
        isEditing
          ? lang.get('role.modal.updateTitle', { name: roleName })
          : lang.get('role.modal.createTitle')
      }
      caption={
        isEditing
          ? lang.get('role.modal.updateCaption')
          : lang.get('role.modal.createCaption')
      }
      okText={isEditing ? lang.get('common.actions.save') : lang.get('common.actions.create')}
      cancelText={!isEditable ? lang.get('common.actions.close') : null}
      okButtonProps={{ hidden: !isEditable }}
      extraActions={isDeletable && (
        <PopconfirmButton
          title={lang.get('role.modal.deleteTitle')}
          icon={<Trash />}
          danger
          loading={deleteRoleMutation.isPending}
          onConfirm={handleDelete}
        >
          {lang.get('common.actions.delete')}
        </PopconfirmButton>
      )}
      width="small"
      open={open}
      confirmLoading={createRoleMutation.isPending || updateRoleMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        disabled={!isEditable}
        onFinish={handleSubmit}
      >
        <Form.Item name="name" label={lang.get('common.form.name.label')}>
          <Input placeholder={lang.get('common.form.name.placeholder')} />
        </Form.Item>
        <Form.Item name="description" label={lang.get('common.form.description.label')}>
          <TextArea placeholder={lang.get('common.form.description.placeholder')} />
        </Form.Item>
        <Form.Item>
          <Form.Title>{lang.get('role.modal.permissions.label')}</Form.Title>
          <Form.Item name="permissions" noStyle>
            <Checkbox.Group>
              <Grid.Row gutter={[24, 24]}>
                {Object.entries(permissionGroups).map(([group, permissions]) => (
                  <Grid.Col key={group} span={12}>
                    <Paragraph className={styles.permissions__label}>{lang.get(`role.groups.${group}`)}</Paragraph>
                    <Space direction="vertical">
                      {permissions.map((permission) => (
                        <Checkbox key={permission} value={permission}>
                          {lang.get(`role.permissions.${permission.toLowerCase()}`)}
                        </Checkbox>
                      ))}
                    </Space>
                  </Grid.Col>
                ))}
              </Grid.Row>
            </Checkbox.Group>
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserRoleModal;

import React from 'react';
import { Form as BaseForm } from 'antd';
import { dot } from 'dot-object';
import { FieldData } from 'rc-field-form/es/interface';
import { ValidationError } from 'yup';

import { useForm } from 'hooks';
import { Nullable } from 'types/common';
import { FormProps } from 'types/components';

import ActionsItem from './ActionsItem';
import CheckboxItem from './CheckboxItem';
import Columns from './Columns';
import Divider from './Divider';
import ErrorList from './ErrorList';
import Item from './Item';
import SwitchItem from './SwitchItem';
import Title from './Title';
import UploadItem from './UploadItem';

const Form = <Values extends object>({
  form,
  validationSchema,
  layout = 'vertical',
  preserve = false,
  onValuesChange,
  onFinish,
  ...props
}: FormProps<Values>) => {
  const formInstance = useForm<Values>(form);

  const setFieldErrors = (name: string, errors?: string[]) => {
    const path: Array<string | number> = [];
    const fields: FieldData[] = [];

    // Generate an error for each part of the field name (in case of an array)
    // (e.g. user[0].name -> ['user'], ['user', 0], ['user', 0, 'name'])
    const parts = name
      .replace(/\[/g, '.')
      .replace(/]/g, '')
      .split('.')
      .map((part) => part.match(/^\d+$/) ? Number(part) : part);

    const isArrayField = name.endsWith(']');
    const hasSubParts = parts.length > 1;

    parts.forEach((part, index) => {
      path.push(part);

      // Skip generating an error for the Form.List fields
      if (!isArrayField && hasSubParts && index === 0) {
        return;
      }

      fields.push({
        name: [...path],
        errors,
      });
    });

    form?.setFields(fields);
  };

  const validateValues = async (values: Values): Promise<Nullable<Values>> => {
    values = formInstance.prepareValues(values);

    if (!form || !validationSchema) {
      return values;
    }

    // Clear the form errors
    Object.keys(dot(values)).forEach((name) => setFieldErrors(name));

    try {
      return await validationSchema.validate(values, { abortEarly: false });
    } catch (err) {
      const error = err as ValidationError;

      // Display the form errors
      const fields: Record<string, string[]> = {};

      error.inner.forEach((error) => {
        const name = error.path as string;

        // Display only the first error
        if (!fields[name]) {
          fields[name] = error.errors;
        }
      });

      Object.entries(fields).forEach(([name, errors]) => setFieldErrors(name, errors));
    }

    return null;
  };

  const handleValuesChange = async (changedValues: Partial<Values>, values: Values) => {
    await validateValues(values);

    onValuesChange?.(changedValues, values);
  };

  const handleFinish = async (values: Values) => {
    const validatedValues = await validateValues(values);

    if (!validatedValues) {
      return;
    }

    if (!onFinish) {
      return;
    }

    const result = await onFinish(validatedValues);

    if (result !== false) {
      form?.resetFields();
    }
  };

  return (
    <BaseForm
      form={form}
      layout={layout}
      preserve={preserve}
      onValuesChange={handleValuesChange}
      onFinish={handleFinish}
      {...props}
    />
  );
};

export default Object.assign(Form, {
  ActionsItem,
  CheckboxItem,
  Columns,
  Divider,
  ErrorList,
  Item,
  List: BaseForm.List,
  SwitchItem,
  Title,
  UploadItem,
});

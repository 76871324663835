import React, { useCallback, useMemo, useState } from 'react';

import { config, moment } from 'data';
import { formatter } from 'helpers';
import { reportService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useLang, useModal, useQuery, useTable, useTableQuery } from 'hooks';
import { useCountryFormatter, useTransactionFormatter } from 'hooks/formatters';
import { DashboardLayout } from 'layouts';
import { BusinessAccountIcon, TableView } from 'components/layout';
import { CountrySelect, ProviderSelect } from 'components/selects';
import { DateRangePicker, Form, Select, Table } from 'components/ui';
import { ExportColumns, TableColumns } from 'types/components';
import { ReportBusinessAccountsDailyRevenueItem, TransactionPayoutMethod } from 'types/models';
import { ReportBusinessAccountsDailyRevenueParams } from 'types/services';

import styles from './styles.module.css';

import TransactionsModal from './TransactionsModal';

const EXPORT_FILE_NAME = 'report-business-accounts-daily-revenue';

type TableParams = Omit<ReportBusinessAccountsDailyRevenueParams, 'dateFrom' | 'dateTo'> & {
  dates: [string, string];
};

const initialTableParams: TableParams = {
  dates: [
    moment().subtract(1, 'day').startOf('day').toISOString(),
    moment().subtract(1, 'day').endOf('day').toISOString(),
  ],
};

const BusinessAccountsDailyRevenuePage = () => {
  const lang = useLang();
  const modal = useModal();
  const table = useTable<ReportBusinessAccountsDailyRevenueItem, TableParams>([config.BUSINESS_ACCOUNTS_DAILY_REVENUE_QUERY_KEY], initialTableParams);
  const countryFormatter = useCountryFormatter();
  const transactionFormatter = useTransactionFormatter();

  const [selectedItem, setSelectedItem] = useState<ReportBusinessAccountsDailyRevenueItem>();

  const reportParams: ReportBusinessAccountsDailyRevenueParams = {
    page: table.page,
    search: table.params.search || undefined,
    providerId: table.params.providerId || undefined,
    payoutMethod: table.params.payoutMethod || undefined,
    country: table.params.country || undefined,
    dateFrom: table.params.dates[0],
    dateTo: table.params.dates[1],
  };

  const reportQuery = useQuery({
    queryKey: [config.BUSINESS_ACCOUNTS_DAILY_REVENUE_QUERY_KEY, reportParams],
    queryFn: () => reportService.getBusinessAccountsDailyRevenue(reportParams),
  });

  const handleViewClick = (item: ReportBusinessAccountsDailyRevenueItem) => () => {
    setSelectedItem(item);

    modal.show();
  };

  useTableQuery(table, reportQuery);

  const formatItemDate = (item: ReportBusinessAccountsDailyRevenueItem) => formatter.formatDate(item.date);

  const formatItemBusinessAccount = (item: ReportBusinessAccountsDailyRevenueItem) => item.client.name;

  const formatItemProvider = (item: ReportBusinessAccountsDailyRevenueItem) => item.provider.name;

  const formatItemPayoutMethod = useCallback((item: ReportBusinessAccountsDailyRevenueItem) => transactionFormatter.formatPayoutMethod(item.payoutMethod), [transactionFormatter]);

  const formatItemCountry = useCallback((item: ReportBusinessAccountsDailyRevenueItem) => countryFormatter.format(item.country), [countryFormatter]);

  const formatItemTransactions = (item: ReportBusinessAccountsDailyRevenueItem) => formatter.formatNumber(item.totalTransactions, 0);

  const formatItemCurrency = (item: ReportBusinessAccountsDailyRevenueItem) => item.currency;

  const formatItemTransactionAmount = (item: ReportBusinessAccountsDailyRevenueItem) => formatter.formatNumber(item.totalAmount);

  const formatItemTransactionFees = (item: ReportBusinessAccountsDailyRevenueItem) => formatter.formatNumber(item.totalTransactionFees);

  const formatItemFxMarkup = (item: ReportBusinessAccountsDailyRevenueItem) => formatter.formatPercent(item.totalFxFees.percent);

  const formatItemFxFees = (item: ReportBusinessAccountsDailyRevenueItem) => formatter.formatNumber(item.totalFxFees.amount);

  const formatItemProviderFees = (item: ReportBusinessAccountsDailyRevenueItem) => formatter.formatNumber(item.totalProviderFees);

  const formatItemNetIncome = (item: ReportBusinessAccountsDailyRevenueItem) => formatter.formatNumber(item.netIncome);

  const exportColumns: ExportColumns<ReportBusinessAccountsDailyRevenueItem> = useMemo(() => [
    {
      title: lang.get('businessAccount.dailyRevenue.date'),
      render: (item) => formatItemDate(item),
    }, {
      title: lang.get('businessAccount.dailyRevenue.businessAccount'),
      render: (item) => formatItemBusinessAccount(item),
    }, {
      title: lang.get('businessAccount.dailyRevenue.provider'),
      render: (item) => formatItemProvider(item),
    }, {
      title: lang.get('businessAccount.dailyRevenue.payoutMethod'),
      render: (item) => formatItemPayoutMethod(item),
    }, {
      title: lang.get('businessAccount.dailyRevenue.country'),
      render: (item) => formatItemCountry(item),
    }, {
      title: lang.get('businessAccount.dailyRevenue.transactions'),
      render: (item) => formatItemTransactions(item),
    }, {
      title: lang.get('businessAccount.dailyRevenue.currency'),
      render: (item) => formatItemCurrency(item),
    }, {
      title: lang.get('businessAccount.dailyRevenue.transactionAmount'),
      render: (item) => formatItemTransactionAmount(item),
    }, {
      title: lang.get('businessAccount.dailyRevenue.transactionFees'),
      render: (item) => formatItemTransactionFees(item),
    }, {
      title: lang.get('businessAccount.dailyRevenue.fxMarkup'),
      render: (item) => formatItemFxMarkup(item),
    }, {
      title: lang.get('businessAccount.dailyRevenue.fxFees'),
      render: (item) => formatItemFxFees(item),
    }, {
      title: lang.get('businessAccount.dailyRevenue.providerFees'),
      render: (item) => formatItemProviderFees(item),
    }, {
      title: lang.get('businessAccount.dailyRevenue.netIncome'),
      render: (item) => formatItemNetIncome(item),
    },
  ], [lang, formatItemPayoutMethod, formatItemCountry]);

  const tableColumns: TableColumns<ReportBusinessAccountsDailyRevenueItem> = [
    {
      className: styles.table__date,
      key: 'date',
      title: lang.get('businessAccount.dailyRevenue.date'),
      render: (_, item) => formatItemDate(item),
    }, {
      key: 'businessAccount',
      title: lang.get('businessAccount.dailyRevenue.businessAccount'),
      render: (_, item) => (
        <Table.Truncate title={formatItemBusinessAccount(item)}>
          <BusinessAccountIcon businessAccountType={item.client.type} />
          <span>{formatItemBusinessAccount(item)}</span>
        </Table.Truncate>
      ),
    }, {
      key: 'provider',
      title: lang.get('businessAccount.dailyRevenue.provider'),
      render: (_, item) => <Table.Truncate>{formatItemProvider(item)}</Table.Truncate>,
    }, {
      key: 'payoutMethod',
      title: lang.get('businessAccount.dailyRevenue.payoutMethod'),
      render: (_, item) => formatItemPayoutMethod(item),
    }, {
      key: 'country',
      title: lang.get('businessAccount.dailyRevenue.country'),
      render: (_, item) => formatItemCountry(item),
    }, {
      key: 'transactions',
      title: lang.get('businessAccount.dailyRevenue.transactions'),
      render: (_, item) => formatItemTransactions(item),
    }, {
      key: 'currency',
      title: lang.get('businessAccount.dailyRevenue.currency'),
      render: (_, item) => formatItemCurrency(item),
    }, {
      className: styles.table__balance,
      key: 'transactionAmount',
      title: lang.get('businessAccount.dailyRevenue.transactionAmount'),
      render: (_, item) => formatItemTransactionAmount(item),
    }, {
      className: styles.table__balance,
      key: 'transactionFees',
      title: lang.get('businessAccount.dailyRevenue.transactionFees'),
      render: (_, item) => formatItemTransactionFees(item),
    }, {
      className: styles.table__balance,
      key: 'fxMarkup',
      title: lang.get('businessAccount.dailyRevenue.fxMarkup'),
      render: (_, item) => formatItemFxMarkup(item),
    }, {
      className: styles.table__balance,
      key: 'fxFees',
      title: lang.get('businessAccount.dailyRevenue.fxFees'),
      render: (_, item) => formatItemFxFees(item),
    }, {
      className: styles.table__balance,
      key: 'providerFees',
      title: lang.get('businessAccount.dailyRevenue.providerFees'),
      render: (_, item) => formatItemProviderFees(item),
    }, {
      className: styles.table__amount,
      key: 'netIncome',
      title: lang.get('businessAccount.dailyRevenue.netIncome'),
      render: (_, item) => formatItemNetIncome(item),
    },
  ];

  return (
    <DashboardLayout title={lang.get('businessAccount.dailyRevenue.title')}>
      <TableView
        title={lang.get('businessAccount.dailyRevenue.title')}
        actions={(
          <TableView.ExportButton<ReportBusinessAccountsDailyRevenueItem>
            table={table}
            fileName={[
              EXPORT_FILE_NAME,
              moment(table.params.dates[0]).format(config.DATE_RAW_FORMAT),
              moment(table.params.dates[1]).format(config.DATE_RAW_FORMAT),
            ].filter(Boolean).join('-')}
            columns={exportColumns}
            fetchData={() => fetchPaginatedResponseFully(reportService.getBusinessAccountsDailyRevenue, reportParams)}
          />
        )}
      >

        <TableView.Filters<TableParams>
          initialValues={initialTableParams}
          values={table.params}
          withSearch
          onSubmit={table.setParams}
        >
          <Form.Item name="providerId" label={lang.get('common.filters.provider')}>
            <ProviderSelect placeholder={lang.get('common.actions.all')} allowClear />
          </Form.Item>
          <Form.Item name="payoutMethod" label={lang.get('businessAccount.dailyRevenue.filters.payoutMethod')}>
            <Select
              placeholder={lang.get('common.actions.all')}
              options={Object.values(TransactionPayoutMethod).map((method) => ({
                value: method,
                label: transactionFormatter.formatPayoutMethod(method),
              }))}
              allowClear
            />
          </Form.Item>
          <Form.Item name="country" label={lang.get('common.filters.country')}>
            <CountrySelect placeholder={lang.get('common.actions.all')} allowClear />
          </Form.Item>
          <Form.Item name="dates" label={lang.get('common.filters.date')}>
            <DateRangePicker maxDate={moment().subtract(1, 'day').endOf('day')} allowClear={false} />
          </Form.Item>
        </TableView.Filters>

        <TableView.Table<ReportBusinessAccountsDailyRevenueItem>
          table={table}
          columns={tableColumns}
          rowKey={(item) => item.date + item.client.id + item.provider.id + item.payoutMethod + item.country + item.currency}
          loading={reportQuery.isLoading}
          clickable
          onRow={(item) => ({ onClick: handleViewClick(item) })}
        />

        {selectedItem && (
          <TransactionsModal
            item={selectedItem}
            open={modal.open}
            onClose={modal.hide}
          />
        )}

      </TableView>
    </DashboardLayout>
  );
};

export default BusinessAccountsDailyRevenuePage;
